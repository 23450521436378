import React from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

// import './styles.css';

// import required modules
import { Autoplay, Pagination, Navigation } from 'swiper/modules';

export default function Testimonial() {
    return (
        <>
            <Swiper
                spaceBetween={30}
                autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                }}
                pagination={{
                    clickable: true,
                }}
                navigation={true}
                modules={[Autoplay, Pagination, Navigation]}
                className="mySwiper"
            >
                <SwiperSlide>
                    <div className='d-flex flex-column justify-content-center mb-5'>
                        <div className='mx-auto'>
                            <img
                                src="https://mdbcdn.b-cdn.net/img/Photos/Avatars/img%20(10).webp"
                                alt="avatar"
                                className="rounded-circle  mb-4"
                                style={{ width: "150px" }}
                            />
                        </div>
                        <div>
                            <h5 className="mb-3">Maria Kate</h5>
                            <p>Photographer</p>
                            <p className="text-muted ">
                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Minus
                                et deleniti nesciunt sint eligendi reprehenderit reiciendis,
                                quibusdam illo, beatae quia fugit consequatur laudantium velit
                                magnam error. Consectetur distinctio fugit doloremque.
                            </p>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className='d-flex flex-column justify-content-center'>
                        <div className='mx-auto'>
                            <img
                                src="https://mdbcdn.b-cdn.net/img/Photos/Avatars/img%20(10).webp"
                                alt="avatar"
                                className="rounded-circle  mb-4"
                                style={{ width: "150px" }}
                            />
                        </div>
                        <div>
                            <h5 className="mb-3">Maria Kate</h5>
                            <p>Photographer</p>
                            <p className="text-muted">
                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Minus
                                et deleniti nesciunt sint eligendi reprehenderit reiciendis,
                                quibusdam illo, beatae quia fugit consequatur laudantium velit
                                magnam error. Consectetur distinctio fugit doloremque.
                            </p>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className='d-flex flex-column justify-content-center'>
                        <div className='mx-auto'>
                            <img
                                src="https://mdbcdn.b-cdn.net/img/Photos/Avatars/img%20(10).webp"
                                alt="avatar"
                                className="rounded-circle  mb-4"
                                style={{ width: "150px" }}
                            />
                        </div>
                        <div>
                            <h5 className="mb-3">Maria Kate</h5>
                            <p>Photographer</p>
                            <p className="text-muted">
                                {/* <MDBIcon fas icon="quote-left" className="pe-2" /> */}
                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Minus
                                et deleniti nesciunt sint eligendi reprehenderit reiciendis,
                                quibusdam illo, beatae quia fugit consequatur laudantium velit
                                magnam error. Consectetur distinctio fugit doloremque.
                            </p>
                        </div>
                    </div>
                </SwiperSlide>
            </Swiper>
        </>
    );
}
