import React from "react";

const SectionOne = () => {
  return (
    <div className="container">
      <div className="row mt-40">
        <div className="col">
          <h1 class=" text-center">What Is Share-Circle ?</h1>
          <p className="text-center">Delivering scalable lending enabled by digital money</p>
        </div>
      </div>
      <div className="row mt-20">
        <div className="col-md-12 col-lg-6 col-12 d-flex flex-column my-auto">
          <p class="text-dark mb-1">Share-Circle is a registered charity, which enables the delivery of 0% interest, microfinance loans to the unbanked globally.</p>
          <p class="text-dark mb-1">
          Using Bitcoin and Lightning it enables money transfer efficiencies that are not available, using conventional currencies.
          </p>
          <p class="text-dark mb-1">
          The service is built on an automated platform with an efficient, scalable, community lending model.  The platform and service are built around Community Support Partners. (CSPs)  </p>
          <p class="text-dark mb-1">While it has some similarities to other microfinance lending, and crowdfunding platforms, it is significantly more efficient in key areas.</p>
          <p class="text-dark">There is a transparent 10% Community Support Partner (CSP) contribution which provides a way to incentivise, support and reward local community partners for being accessible, responsible lending associates. It uses a simple digital mobile phone-based solution for repaying loans – to save you time, travel and costs.</p>
        </div>
        <div className="d-flex justify-content-center col-md-12 col-lg-6 col-12">
          <img src="../images/graphic-diagram.jpg" className="rounded" alt="demo" width={'80%'} />
        </div>
      </div>
    </div>
  );
};

export default SectionOne;
