import React from "react";
const SectionOne = () => {
  return (
    <div className="container">
      <div className="row mt-40">
        <div className="col-12">
          <h1 className="text-center">Community Support Partner (CSP) </h1>
          <p className="text-center">
          Bitcoin Community Leaders & Share-Circle enable efficient, low-cost local lending
          <img className="mx-auto d-flex" src="../images/Community-support.png" alt="logo" width={"200px"} />

          </p>
        </div>
      </div>
      <div className="row">
        <div className="d-flex justify-content-center col-lg-6 col-md-12 col-12 mt-20">
          <img
            className="rounded"
            src="../images/graphic-diagram.jpg"
            alt="Images"
            width={"80%"}
          />
        </div>
        <div className="col-lg-6 col-md-12 col-12 d-flex flex-column justify-content-center mt-20">

          <p>
            Community Support Partners can be small groups of local people who
            want to enable responsible local borrowing / lending / in your
            community.
          </p>
          <p>
            As a Bitcoin aware community leader, you understand the benefits of Bitcoin and lightning. You understand the needs of people in your community and have established relationships.
          </p>
          <p>
            We are on a mission to help Bitcoin communities grow. As Bitcoin aware Community Partners, (CSPs) you are central to the Share-Circle service.
          </p>
          <p>
            So, we see it as our job to help you. We see you in a similar way as UBER sees Taxi Drivers in their business – Motivated, self-employed, responsible people, who become local partners.
          </p>
          <p>
            We want to reward you for your time, and provide business support and training, to help you and your team to facilitate small business loans locally.          </p>
        </div>
      </div>
    </div>
  );
};

export default SectionOne;
