import React from "react";

const SectionTwo = () => {
  return (
    <div
      className="container-fluid mt-40"
      style={{ backgroundColor: "#252525", width: "100%" }}
    >
      <div classname="container bg-dark my-5">
        <div className="row">
          <div className="row justify-content-center ">
            <div className="col-md-12">
              <div className="text-center">
                <h3 className="section-title pt-5 " style={{ color: "white" }}>
                  How Share-Circle Works
                </h3>
                <h1 className=" text-light">
                Your donation can change many lives
                </h1>
              </div>
            </div>
          </div>

          {/* ---------------sectionwithimg--------------------------- */}

          {/* <div className="row justify-content-center mt-3 d-md-block d-lg-block d-none">
          <div className="col-12 text-center m-0 p-0">
            <h2 className="text-light m-0 p-0">Your Donation</h2>
            <p className="text-light m-0 p-0">Browse through the profiles of our borrowers.</p>
          </div>
        </div>
          <div className="row">
          <div className="col-md-3 col-lg-4 col-0 d-flex align-items-center justify-content-end p-0 m-0  text-right ">
            <div className="d-md-block d-lg-block d-none">
              <h2 className="text-light text-end"> Funds Recycled</h2>
              <p className="text-light text-end">The repaid funds are re-lent to other entrepreneurs</p>
            </div>
          </div>
          <div className="col-md-6 col-lg-4 col-12 p-0 m-0 d-flex justify-content-center">
            <img
              src="./images/home-work.png"
              alt="logo"
              width={"80%"}
              height={"400px"}
            />
          </div>
          <div className="col-md-3 col-lg-4 col-0 d-flex align-items-center p-0 m-0 ">
            <div className="d-md-block d-lg-block d-none">
              <h2 className="text-light"> Progress</h2>
              <p className="text-light"> You get updates as their business grows </p>
            </div>
          </div>
        </div>
          <div className="row d-md-block d-lg-block d-none">
          <div className="col-12 text-center">
            <h2 className="text-light">They Repay</h2>
            <p className="text-light">They are up and running and loan is repaid</p>
          </div>
        </div> */}
        <div className="d-flex justify-content-center align-items-center">
        <img className="yourdonation-image" src="./images/Yourdonation3.png" alt="imsadf" />
        </div>
        </div>

        {/* ---------------------cardSection------------------------- */}

        {/* <div className="row">
          <div className="col-lg-3 col-md-6 col-12">
            <img src="../images/user.png" alt="img" width={"100%"} />
          </div>
          <div className="col-lg-3 col-md-6 col-12">
            <img src="../images/user.png" alt="img" width={"100%"} />
          </div>
          <div className="col-lg-3 col-md-6 col-12">
            <img src="../images/user.png" alt="img" width={"100%"} />
          </div>
          <div className="col-lg-3 col-md-6 col-12">
            <img src="../images/user.png" alt="img" width={"100%"} />
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default SectionTwo;
