import React from "react";
import { BsFacebook, BsLinkedin } from "react-icons/bs";
import { AiFillTwitterCircle } from "react-icons/ai";
import { RiInstagramFill } from "react-icons/ri";
import { FaPaypal } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import { useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import axiosInstance from "../../Axois/Axois";

const Footer = () => {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [email, setEmail] = useState("");
  const [question, setQuestion] = useState("");

  const handleSubmit = async () => {
    const formData = new FormData();
    formData.append("name", name);
    formData.append("phone", phone);
    formData.append("email", email);
    formData.append("address", address);
    formData.append("question", question);
    try {
      const response = await axiosInstance.post("/contact", formData);

      console.log(response, "Contact12");
      if (response.data.status === true) {
        toast.success(response?.data?.message);
        setName("");
        setPhone("");
        setEmail("");
        setAddress("");
        setQuestion("");
      }
    } catch (error) {
      toast.error(error?.message);
    }
  };

  return (
    <div className="container-fluid py-3 px-lg-5 px-md-4 footer12">
      <Toaster position="top-right" reverseOrder={false} />
      <div className="row mt-4 ">
        <div className="col-md-6 col-lg-3 col-12">
          {/* <div className="col-9 my-2 d-flex justify-content-center">
            <Link to={"/"}>
              <img src="../images/logo1.png" alt="logo" className="logo" />
            </Link>
          </div> */}
          <div>
            <h3>BORROW</h3>
            <p>Empowering entrepreneurs with innovative loans.</p>
            <NavLink to="/loanform" className="link-style2">
              Apply now
            </NavLink>
          </div>
          <div className="my-2">
            <h3 className="my-2">Transform lives with a simple loan.</h3>
            <NavLink to={"/donate"} className="link-style2 ">
              <button className="btn btn-warning text-white">Donate</button>
            </NavLink>
          </div>
          <div className="my-3">
            <h3>EXPLORE</h3>
            <ul className="p-0">
              <li>
                <NavLink className="link-style2">Success stories</NavLink>
              </li>
              <li>
                <NavLink className="link-style2">Financial governance</NavLink>
              </li>
              <li>
                <NavLink className="link-style2">Terms of use</NavLink>
              </li>
              <li>
                <NavLink className="link-style2">Policies</NavLink>
              </li>
              <li>
                <NavLink className="link-style2" to={"/security"}>
                  Security & Trust
                </NavLink>
              </li>
              <li>
                <NavLink className="link-style2" to={"/lightning"}>
                  Bitcoin & Lightning Network
                </NavLink>
              </li>
              <li>
                <NavLink className="link-style2">
                  CSP Training & Support site
                </NavLink>
              </li>
            </ul>
          </div>

          {/* <ul>
            <li>Share-Circle</li>
            <li>1400 K Street NW, Suite 1255 Washington, DC 20005</li>
            <li>(202) 628-3560</li>
          </ul> */}
        </div>
        <div className="col-md-6 col-lg-3 col-12">
          <div>
            <h3>SHARE-CIRCLE MEDIA</h3>
            <p>
              <NavLink className="link-style2">
                Press/media materials News
              </NavLink>
            </p>
          </div>

          <div className="my-3">
            <h3>GET TO KNOW US</h3>
            <ul className="p-0">
              <li>
                <NavLink className="link-style2">About us</NavLink>
              </li>
              <li>
                <NavLink className="link-style2">
                  How Share-Circle works
                </NavLink>
              </li>
              <li>
                <NavLink className="link-style2">Why Share-Circle</NavLink>
              </li>
              <li>
                <NavLink className="link-style2">
                  Our organizational profile
                </NavLink>
              </li>
              <li>
                <NavLink className="link-style2">Organisation board</NavLink>
              </li>
              <li>
                <NavLink className="link-style2">Key partners</NavLink>
              </li>
              <li>
                <NavLink className="link-style2" to={"/Faq"}>
                  FAQs
                </NavLink>
              </li>
            </ul>
          </div>

          <div className="my-3">
            <h3>FOLLOW US</h3>
            <div className="d-flex align-items-center gap-2 p-0">
              <NavLink>
                <BsFacebook color="#3b5998" size={"35px"} />
              </NavLink>
              <NavLink>
                <BsLinkedin color="#0072b1" size={"34px"} />
              </NavLink>
              <NavLink>
                <AiFillTwitterCircle color="#00acee" size={"40px"} />
              </NavLink>
              <NavLink>
                <RiInstagramFill color="#E1306C" size={"40px"} />
              </NavLink>
            </div>
          </div>
        </div>

        {/* <div className="col-md-6 col-lg-3 col-12">
        <h3>Main Pages</h3>

          <ul>
            <li>Privacy Policy</li>
            <li>Terms of Use</li>
            <li>Press / media material</li>
            <li>stories</li>
            <li>CSP training & support site</li>
            <li>Success stories</li>
            <li>News</li>
            <li>Lightning</li>
          </ul>
        </div> */}
        <div className="col-md-12 col-lg-6 col-12 order-lg-0 order-md-4  order-0">
          <p>
            Discover Share-Circle, a UK nonprofit revolutionising lending to the
            unbanked. Our interest-free Microloans fuel micro-entrepreneurs,
            creating a ripple effect of progress and hope.
          </p>
          <NavLink className="link-style2" to={"/donate"}>
            Donate to Share-Circle
          </NavLink>

          <div className="my-3">
            <h3>Contact us</h3>
          </div>
          <div>
            {/* <form> */}
            <div className="row">
              <div class="col-md-6 mb-3">
                <input
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                  type="text"
                  class="form-control"
                  style={{ padding: "10px" }}
                  placeholder="Enter your name"
                />
              </div>
              <div class="col-md-6 mb-3">
                <input
                  onChange={(e) => setPhone(e.target.value)}
                  value={phone}
                  type="text"
                  class="form-control"
                  style={{ padding: "10px" }}
                  placeholder="Enter your phone no."
                />
              </div>
            </div>
            <div class="mb-3">
              <input
                onChange={(e) => setAddress(e.target.value)}
                value={address}
                type="text"
                class="form-control"
                style={{ padding: "10px" }}
                placeholder="Enter your address"
              />
            </div>
            <div class="mb-3">
              <input
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                type="email"
                class="form-control"
                style={{ padding: "10px" }}
                placeholder="Enter your email address"
              />
            </div>
            <div class="mb-3">
              <textarea
                onChange={(e) => setQuestion(e.target.value)}
                value={question}
                class="form-control"
                rows="3"
                style={{ padding: "10px" }}
                id="message-text"
                placeholder="Enter your question"
              ></textarea>
            </div>
            <div class="text-end mb-3">
              <button
                onClick={handleSubmit}
                className="btn btn-warning text-white"
              >
                Submit
              </button>
            </div>

            {/* </form> */}
          </div>

          <div></div>
        </div>
      </div>

      <div className="row my-3 bg-dark d-flex  justify-content-around align-items-center round">
        <div className="col-lg-5 col-md-4 col-sm-12 d-flex justify-content-lg-start justify-content-md-start justify-content-center ">
          <img src="../images/logo1.png" width={"120px"} alt="logo" />
        </div>
        <div
          className="d-flex flex-lg-nowrap flex-md-wrap flex-wrap
           col-lg-5 col-md-8 col-sm-12 justify-content-around"
        >
          <div className="card-footer">
            <img src="../images/bitcoin11.png" width={"120px"} alt="bitcoin" />
          </div>
          <div className="card-footer">
            <img
              src="../images/visa-cart22.png"
              width={"80px"}
              alt="visacart"
            />
          </div>
          <div className="card-footer">
            <img
              src="../images/master-cart11.png"
              width={"90px"}
              alt="mastercart"
            />
          </div>
          <div className="card-footer">
            <img
              src="../images/lighting22.png"
              width={"40px"}
              alt="lightning"
            />
          </div>
          <div className="card-footer">
            <img src="../images/tether.png" width={"50px"} alt="Tether" />
          </div>
          <div className="card-footer">
            <FaPaypal style={{ fontSize: "35px", color: "#0079C1" }} />
          </div>
        </div>
      </div>
      <div className="text-center">
        <p className="fw-bold">
          Copyright @ 2023. All rights reserved by Share-Circle
        </p>
      </div>
    </div>
  );
};

export default Footer;
