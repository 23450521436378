import React from "react";
import { useSelector } from "react-redux";
// import { NavLink } from 'react-router-dom';

const ReviewDonate = ({ fntData }) => {
  const { items } = useSelector((state) => state.chart);
  const totalAmount = items?.reduce(
    (total, data) => total + parseInt(data?.amount),
    0
  );
  // const [moredetail, setMoredetail] = useState(false);
  return (
    <div>
      <div className="row my-3">
        <div className="d-flex justify-content-between align-items-center bg-warning p-2 my-2">
          <div className="fw-bold">Borrowers</div>
          <div className="fw-bold">Total</div>
        </div>
        {items?.map((data, index) => {
          return (
            <div>
              <div className="fw-bold fs-5 text-warning my-2">{data?.name}</div>
              <div className="d-flex justify-content-between">
                <p>
                  
                 {data?.address}
                </p>
                <p className="fw-bold">${data?.amount}</p>
              </div>
            </div>
          );
        })}

        <div className="">
          <div className="border-top border-2 py-2 text-end fw-bold">
            Donation to Share-Circle: $ {totalAmount/100*10}
          </div>
          <div className="border-top border-2 py-2 text-end fw-bold">
            Order Total $ {totalAmount}
          </div>
          <div className="border-top border-2 py-2 text-end fw-bold">
            Total Due $ {totalAmount}
          </div>
        </div>
        <div className="d-flex justify-content-between my-2">
          {/* <div className="my-2">
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                value=""
                onChange={() => setMoredetail(!moredetail)}
                id="flexCheckDefault"
              />
              <label class="form-check-label" for="flexCheckDefault">
                Donate on behalf of someone else
              </label>
            </div>
            {moredetail === true ? (
              <div
                className="my-3  p-3"
                style={{
                  border: "1px solid rgba(0, 0, 0, 0.1)",
                  borderRadius: "4px",
                }}
              >
                <div className="">
                  <input
                    type="email"
                    className="p-2 w-100"
                    style={{
                      border: "1px solid rgba(0, 0, 0, 0.1)",
                      borderRadius: "4px",
                    }}
                    placeholder="Enter your email"
                  />
                </div>
                <div className="my-2">
                  <input
                    type="text"
                    className="p-2 w-100"
                    style={{
                      border: "1px solid rgba(0, 0, 0, 0.1)",
                      borderRadius: "4px",
                    }}
                    placeholder="Enter your note..."
                  />
                </div>
                <div>
                  <button className="btn btn-warning text-white">Submit</button>
                </div>
              </div>
            ) : null}
          </div> */}
          <div>
            <button
              onClick={() => fntData("")}
              className="fw-bold btn btn-warning text-white me-3"
            >
              Back
            </button>

            <button
              onClick={() => fntData(2)}
              className="fw-bold btn btn-warning text-white"
            >
              Continue
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReviewDonate;
