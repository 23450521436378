import React from "react";

const SectionOne = () => {
  return (
    <div className="container">
      <div className="row mt-40">
        <div className="col-12">
          <h1 className="text-center fw-bold">Borrow </h1>
          <p className="text-center">
            <b> By being a responsible borrower, You enable others to thrive also</b>
          </p>
        </div>
      </div>
      <div className="row">
        <div className="d-flex justify-content-center col-lg-6 col-md-12 col-12 mt-20">
          {/* <img
            className="rounded"
            src="../images/borrow12.jpg"
            alt="Images"
            width={"100%"}
          /> */}
          <img
            className="rounded"
            src="../images/graphic-diagram.jpg"
            alt="Images"
            width={"80%"}
          />
        </div>
        <div className="col-lg-6 col-md-12 col-12 mt-20">
          <img
            className="mx-auto d-flex my-3"
            src="../images/bekari.png"
            alt="logo"
            width={"150px"}
          />

          <p>
            The Share-Circle Borrow service is available, where you have an
            authorized Community Service Partner. These will initially be where
            a Community Partner is familiar with Bitcoin and Lightning, and has
            joined Share-Circle as a Lending Partner
          </p>

          <p>
            If there is no local authorized Community Support Partner (CSP), you
            can talk to a responsible local community support organization and
            ask if they are familiar with Bitcoin, and ask if they could join
            the Share-Circle Community Support Partner (CSP)Program.
          </p>
        </div>
      </div>
    </div>
  );
};

export default SectionOne;
