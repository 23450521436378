import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage";
import LoginSlice from "./slice/LoginSlice";
import cartSlice from './slice/Addtochart'
import wishlistSlice from './slice/wishlistSlice';
const rootPersistConfig = {
  key: "root",
  storage,
};

const rootReducer = combineReducers({
   Login: LoginSlice,
   chart: cartSlice,
   wishlist: wishlistSlice,
});

export { rootPersistConfig, rootReducer };
