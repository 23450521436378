import React, { useEffect } from 'react'

const Security = () => {
    
    useEffect(() => {
        window.scrollTo(0, 0)
    
      }, [])
    return (
        <div className='container py-3' style={{ marginTop: '100px' }}>
            <div className='row my-5 '>
                <h1 className='text-center py-3 fw-bold' style={{fontSize:"3rem"}}>Security and Trust</h1>
                <p>Share-Circle redefines how borrowing solutions can be delivered for the unbanked.
                    We work to implement the tools that ensure responsible lending and borrowing.
                    We simplify some aspects, and introduce important changes to make it more financially efficient, and take out layers of overhead in the middle.
                </p>
              

                <h4 className='fw-bold'>Community Support Partners</h4>
                <p>We put in place measures to ensure the security and trustworthiness of our model and community partners, ensuring that that are in good standing and active in their local community. This gives donors confidence that their money will be used optimally.
                    Funds are disbursed through our Community Service Partners (CSPs)
                </p>

                <h4 className='fw-bold'>Entrepreneur/ borrower’s</h4>
                <p>
                    There is independent verification of entrepreneur/ borrower’s name and national identification using ID card and verifying against national identity registries.
                    Entrepreneurs are required to provide telephone numbers of at least three local contacts that can vouch for their identity and reputation, before proposing lending proposals and generating funds.
                    </p>
          
                <p>
                    Other steps are available and used, like consulting national credit bureau to ensure the entrepreneur is not in default on any other loans.
                    We support responsible participation by progressing from small loans for new borrowers to larger loans and projects gradually over time.
                    </p>
                
            </div>
          
        </div>
    )
}

export default Security