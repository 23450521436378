import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Mousewheel, Pagination } from 'swiper/modules';
import './light.css'
import { useEffect } from 'react';
const Lightining = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    return (
        <div className='container mt-150'>
            <h1 className='text-center my-2 fw-bold'>Lightning</h1>
            <div className='row my-5 '>
                <div className='col-md-12 col-lg-6 col-12 d-flex flex-column justify-content-center'>
                    <p>The Share-Circle service and app enables small unbanked businesses borrow and repay money in a better way than ever before. It is accessible, efficient and saves you money.</p>
                    <p>Once your business loan is invested the process of repaying it shouldn’t involve you having to travel distances to transfer money, or waste business or family time. It uses a technology via your mobile phone called Lightning which is built upon the Bitcoin network. It can be as easy as sending a text or email from your mobile phone.   </p>
                    <p>This proven technology is increasingly used throughout the world. In the same way that the internet changed how we send and receive information, Digital money changes how we send and receive value.</p>
                    <p>The Bitcoin and Lightning network is the longest established, most secure, stable and widely used globally, and is often described as the Internet of money. </p>
                    <p>Your CSP (Community Service Partner) will show you how to use it. Electronic money makes the whole process much more efficient and thus cheaper. </p>
                </div>
                <div className='col-md-8 col-lg-5 col-12 d-flex justify-content-center my-3 mx-auto swiper-height' >
                    {/* <img  src='https://images.pexels.com/photos/50987/money-card-business-credit-card-50987.jpeg?auto=compress&cs=tinysrgb&w=600' width="100%" alt='images'/> */}
                    <Swiper
                        direction={'vertical'}
                        slidesPerView={1}
                        spaceBetween={30}
                        mousewheel={true}
                        pagination={{
                          clickable: true,
                        }}
                        modules={[Mousewheel, Pagination]}
                        className="mySwiper"
                    >
                        <SwiperSlide>
                            <img className='rounded' src='../images/lighting11.png' alt='lighting11'  />
                        </SwiperSlide>
                        <SwiperSlide>
                            <img  className='rounded' src='../images/lighting12.png' alt='lighting12'/>
                        </SwiperSlide>
                        <SwiperSlide>
                            <img className='rounded' src='../images/lighting13.png' alt='lighting13'/>
                        </SwiperSlide>
                    </Swiper>
                </div>
            </div>
        </div>
    )
}

export default Lightining