import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'

const Form2 = ({ datBtn }) => {

    const [showDiv, setShowDiv] = useState(false)

    useEffect(() => {
        window.scrollTo(0, 0)

    }, [])
    return (
        <div className='container' style={{ marginTop: "120px" }}>
            <div className='row my-3 justify-content-center lab-inpt'>
                <h3 className='text-center fs-1 fw-bold my-4'>Add Application</h3>

                <div className='row justify-content-around align-items-center my-2'>
                    <div className='col-lg-3'>
                        <label className='fw-bold'>Borrower name</label>
                        <input className='w-100' style={{ padding: "10px" }} />
                    </div>
                    <div className='col-lg-3'>
                        <label className='fw-bold'>Father name</label>
                        <input className='w-100' style={{ padding: "10px" }} />
                    </div>
                    <div className='col-lg-3 '>
                        <label className='fw-bold'>CNIC</label>
                        <input className='w-100' style={{ padding: "10px" }} />
                    </div>
                </div>
                <div className='row justify-content-around align-items-center my-2'>
                    <div className='col-lg-3'>
                        <label className='fw-bold'>Gender</label>
                        <select class="form-select form-select-lg mb-3 col-md-3" aria-label=".form-select-lg example">
                            <option selected>Select Gender</option>
                            <option value="1">Male</option>
                            <option value="2">Female</option>
                            <option value="3">Transgender</option>
                        </select>
                    </div>
                    <div className='col-lg-3'>
                        <label className='fw-bold'>Marital Status</label>
                        <select class="form-select form-select-lg mb-3 col-md-3" aria-label=".form-select-lg example">
                            <option selected>Select Marital Status</option>
                            <option value="1">Single</option>
                            <option value="2">Married</option>
                            <option value="3">Divorced</option>
                            <option value="3">Widow</option>
                            <option value="3">Widower</option>
                            <option value="3">Other</option>
                        </select>
                    </div>
                    <div className='col-lg-3 '>
                        <label className='fw-bold'>Residence status</label>
                        <select class="form-select form-select-lg mb-3 col-md-3" aria-label=".form-select-lg example">
                            <option selected>Select Home Status</option>
                            <option value="1">Own</option>
                            <option value="2">Rended</option>
                            <option value="3">Mortgage</option>
                            <option value="3">Other</option>
                        </select>
                    </div>
                </div>
                <div className='row justify-content-around align-items-center my-2'>
                    <div className='col-lg-3'>
                        <label className='fw-bold'>Rent</label>
                        <input className='w-100' style={{ padding: "10px" }} />
                        {/* <select class="form-select form-select-lg mb-3 col-md-3" aria-label=".form-select-lg example">
                            <option selected>Open this select menu</option>
                            <option value="1">One</option>
                            <option value="2">Two</option>
                            <option value="3">Three</option>
                        </select> */}
                    </div>
                    <div className='col-lg-3'>
                        <label className='fw-bold'>Date of Birth</label>
                        <input type='date' className='w-100' style={{ padding: "10px" }} />

                    </div>
                    <div className='col-lg-3 '>
                        <label className='fw-bold'>Religion</label>
                        <select class="form-select form-select-lg mb-3 col-md-3" aria-label=".form-select-lg example">
                            <option selected>Select Religion</option>
                            <option value="1">Islam</option>
                            <option value="2">Christianity</option>
                            <option value="3">Ahmadi</option>
                            <option value="3">Hinduism</option>
                            <option value="3">Gnosticism</option>
                            <option value="3">Buddhism</option>
                            <option value="3">Judaism</option>
                        </select>
                    </div>
                </div>
                <div className='row justify-content-around align-items-center my-2'>
                    <div className='col-lg-3'>
                        <label className='fw-bold'>How many dependents?</label>
                        <input type='text' className='w-100' style={{ padding: "10px" }} />
                    </div>
                    <div className='col-lg-3'>
                        <label className='fw-bold'>Phone</label>
                        <input type='number' className='w-100' style={{ padding: "10px" }} />
                    </div>
                    <div className='col-lg-3 '>
                        <label className='fw-bold'>Country</label>
                        <input type='text' className='w-100' style={{ padding: "10px" }} />
                    </div>
                </div>
                <div className='row justify-content-around align-items-center my-2'>
                    <div className='col-lg-3'>
                        <label className='fw-bold'>State</label>
                        <input type='text' className='w-100' style={{ padding: "10px" }} />
                    </div>
                    <div className='col-lg-3'>
                        <label className='fw-bold'>City</label>
                        <input type='number' className='w-100' style={{ padding: "10px" }} />
                    </div>
                    <div className='col-lg-3 '>
                        <label className='fw-bold'>Branch</label>
                        <input type='text' className='w-100' style={{ padding: "10px" }} />
                    </div>
                </div>


                <div className='row my-2'>
                    <div className='col-lg-11 mx-auto'>
                        <div className='fw-bold mb-2'>Address</div>
                        <textarea className='w-100' rows={'5'}></textarea>
                    </div>
                </div>

                <div className='row justify-content-around align-items-center my-2'>
                    <div className='col-lg-3'>
                        <label className='fw-bold'>Borrower image</label>
                        <input type='file' className='w-100' style={{ padding: "10px" }} />
                    </div>
                    <div className='col-lg-3'>
                        <label className='fw-bold'>Borrower CNIC Front</label>
                        <input type='file' className='w-100' style={{ padding: "10px" }} />
                    </div>
                    <div className='col-lg-3 '>
                        <label className='fw-bold'>Borrower CNIC Back</label>
                        <input type='file' className='w-100' style={{ padding: "10px" }} />
                    </div>
                </div>

                <div className='row my-2'>
                    <div class="col-lg-11 mx-auto form-check mt-2 ">
                        <input onChange={() => setShowDiv(!showDiv)} class="form-check-input" type="checkbox" value="" id="flexCheckIndeterminate" />
                        <label class="form-check-label fw-bold" for="flexCheckIndeterminate">
                            Are you Employed.
                        </label>
                    </div>
                </div>

                {
                    showDiv && (<div className='row my-2'>
                        <div className='col-lg-11 mx-auto'>
                            <div className='fw-bold'>What you do</div>
                            <textarea className='w-100' rows={'3'}></textarea>
                        </div>
                    </div>)
                }

                {
                    showDiv && (<div className='row justify-content-around align-items-center my-2'>
                        <div className='col-lg-5'>
                            <label className='fw-bold'>Employee Type</label>
                            <select class="form-select form-select-lg mb-3 col-md-3" aria-label=".form-select-lg example">
                                <option selected>Select Type</option>
                                <option value="1">Government</option>
                                <option value="2">Private</option>
                            </select>
                        </div>
                        <div className='col-lg-5'>
                            <label className='fw-bold'>Department</label>
                            <select class="form-select form-select-lg mb-3 col-md-3" aria-label=".form-select-lg example">
                                <option selected>Select Department</option>
                                <option value="1">Police</option>
                                <option value="2">Army</option>
                                <option value="3">Taxation</option>
                                <option value="3">Excise</option>
                                <option value="3">Rescue</option>
                                <option value="3">Other</option>
                            </select>
                        </div>
                    </div>)
                }


                <div className='row justify-content-around align-items-center my-2'>
                    <div className='text-center fs-3 fw-bold my-3'>Project Detail</div>
                    <div className='col-lg-3'>
                        <label className='fw-bold'>Business Title</label>
                        <input className='w-100' style={{ padding: '10px' }} />
                    </div>
                    <div className='col-lg-3'>
                        <label className='fw-bold'>Required fund</label>
                        <input className='w-100' style={{ padding: '10px' }} />
                    </div>
                    <div className='col-lg-3'>
                        <label className='fw-bold'>Category</label>
                        <select class="form-select form-select-lg mb-3 col-md-3" aria-label=".form-select-lg example">
                            <option selected>Select Category</option>
                            <option value="1">Agriculture</option>
                            <option value="2">Arts</option>
                            <option value="3">Clothing</option>
                            <option value="4">Construction</option>
                            <option value="5">Educations</option>
                            <option value="6">Entainment</option>
                            <option value="7">Food</option>
                            <option value="8">Health</option>
                            <option value="9">Housing</option>
                            <option value="10">Retails</option>
                            <option value="11">Manufacturing</option>
                            <option value="12">Services</option>
                            <option value="13">Wholesale</option>
                            <option value="14">Others</option>
                        </select>
                    </div>
                </div>

                <div className='row justify-content-around align-items-center my-2'>
                    <div className='col-lg-5'>
                        <label className='fw-bold'>Project image</label>
                        <input type='file' className='w-100' style={{ padding: "10px" }} />
                    </div>
                    <div className='col-lg-5'>
                        <label className='fw-bold'>Project Video(Optional)</label>
                        <input type='file' className='w-100' style={{ padding: "10px" }} />
                    </div>
                </div>

                <div className='row my-2'>
                    <div className='col-lg-11 mx-auto '>
                        <div className='fw-bold mb-2'>Description of the business you want to start</div>
                        <textarea className='w-100' rows={'5'}></textarea>

                        <div className='d-flex justify-content-between my-2'>
                            <NavLink onClick={() => datBtn(2)} className='btn btn-warning text-white'>Previous</NavLink>
                            <NavLink onClick={() => datBtn(4)} className='btn btn-warning text-white'>Save and continue</NavLink>
                        </div>
                    </div>
                </div>


            </div>
        </div>
    )
}

export default Form2