import React, { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { NavLink, useNavigate } from "react-router-dom";
import axiosInstance from "../../Axois/Axois";

const Signup = () => {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [cellNo, setCellNo] = useState("");
  const [password, setPassword] = useState("");
  const [fName, setFName] = useState("");
  const [Address, setAddress] = useState("");

  const handleSignup = async () => {
    const formData = new FormData();
    formData.append("name", fName);
    formData.append("email", email);
    formData.append("phone", cellNo);
    formData.append("password", password);
    formData.append("address", Address);
    try {
      const response = await axiosInstance.post("/donor", formData);

      console.log(response, "Sign Up Successful");
      if (response.data.status === true) {
        toast.success(response.data.message);
        navigate("/login");
        setFName("");
        setEmail("");
        setCellNo("");
        setPassword("");
        setAddress("");
      }
    } catch (error) {
      toast.error(error?.message);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="container" style={{ marginTop: "120px" }}>
        <Toaster position="top-right" reverseOrder={false} />
        <div className="row">
          <div className="text-center fs-4 fw-bold">Sign Up</div>
          <div className="text-center">Enter your personal details below</div>
          <div className="col-lg-6 col-md-8 mx-auto my-4">
            <div
              className="py-5 px-3 sign-up rounded"
              style={{
                backgroundColor: "#f8f8f8",
                border: "1px solid rgba(1, 1, 1, 0.3)",
              }}
            >
              <input
                className="w-100"
                type="text"
                placeholder="Full Name"
                value={fName}
                onChange={(e) => setFName(e.target.value)}
              />

              <input
                className="w-100"
                placeholder="Email"
                value={email}
                type="email"
                onChange={(e) => setEmail(e.target.value)}
              />
              <input
                className="w-100"
                placeholder="Phone No"
                value={cellNo}
                type="number"
                onChange={(e) => setCellNo(e.target.value)}
              />

              <input
                className="w-100"
                placeholder="Password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <input
                className="w-100"
                placeholder="Address"
                type="text"
                value={Address}
                onChange={(e) => setAddress(e.target.value)}
              />
              <button
                className="btn btn-warning w-100 my-3 fw-bold"
                onClick={handleSignup}
              >
                Sign Up
              </button>
              <div className="text-center my-1 fw-bold">Already a Member</div>
              <NavLink to={"/login"} className="btn btn-warning w-100 fw-bold">
                Log In
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Signup;
