import React, { useEffect } from 'react'
import HomeBanner from '../component/homecomponent/HomeBanner'
import SectionOne from '../component/Entrepreneur/SectionOne'
import SEctionTwo from '../component/Entrepreneur/SEctionTwo'
const Entrepreneur = () => {

  useEffect(() => {
    window.scrollTo(0, 0)

  }, [])
  return (
    <div>
      <HomeBanner/>
      <SectionOne/>
      <SEctionTwo/>
    </div>
  )
}

export default Entrepreneur