import React, { useEffect, useState } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../../Axois/Axois';
// import { NavLink, useLocation } from 'react-router-dom';

const Login = () => {
  const navigate = useNavigate();

  // const location = useLocation();
  // const searchParams = new URLSearchParams(location.search);

  // const status = searchParams.get("status");
  // const message = searchParams.get("message");

  // console.log("Status:", status);
  // console.log("Message:", message);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleLogIn = async () => {
    // console.log("adf")
    const formData = new FormData();
    formData.append('email', email)
    formData.append('password', password)

    try {
      const response = await axiosInstance.post('/login', formData)
      console.log(response,'login')
      if (response?.data?.data?.user?.role?.name === 'donor') {
        toast.success(response?.data?.data?.message)
        localStorage.setItem("useToken", response?.data?.data?.token);
        navigate("/")
        setEmail("")
        setPassword("")
      }
      else{
        toast.error("please Check Login")
      }
   

    } catch (error) {
      toast.error(error.message)

    }
  };

  useEffect(() => {
    window.scrollTo(0, 0)

  }, [])

  return (
    <div className='container' style={{ marginTop: "120px" }}>
      <Toaster
        position="top-right"
        reverseOrder={false}
      />
      <div className='row'>
        <div className='text-center fs-4 fw-bold'>Log In</div>
        <div className='col-lg-6 col-md-8 mx-auto my-4'>
          <div
            className='py-5 px-3 sign-up rounded'
            style={{ backgroundColor: '#f8f8f8', border: '1px solid rgba(1, 1, 1, 0.3)' }}
          >
            <input
              className='w-100'
              placeholder='Email'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <input
              className='w-100'
              placeholder='Password'
              type='password'
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <button className='btn btn-warning w-100 center my-3 fw-bold' onClick={handleLogIn}>
              Log In
            </button>
            <p className='text-center bold'>
              Don't have acount ?
            </p>
            <button className='btn btn-warning w-100  fw-bold' onClick={()=>navigate('/signup')}>
              Signup
            </button>
          </div>
         
        </div>
      </div>
    </div>
  );
};

export default Login;
