/* eslint-disable array-callback-return */
import React from "react";
import { MdDelete } from "react-icons/md";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { removeItem } from "../../Auth/slice/Addtochart";
// import ProgressBar from "@ramonak/react-progress-bar";

const Checkout = ({ fntData }) => {
  const { items } = useSelector((state) => state.chart);
  const dispatch = useDispatch();
  // const adsf = "10%"
  const totalAmount = items?.reduce(
    (total, data) => total + parseInt(data?.amount),
    0
  );

  const deletechart = (id) => {
    dispatch(removeItem(id));
  };
  return (
    <>
      <div className="row my-3">
        {items?.length > 0 ? (
          items.map((data, index) => (
            <div className="col-12">
              <div
                className="row justify-content-between align-items-center px-2 py-3 border my-2"
                key={index}
              >
                <div className="col-lg-1 col-md-2  col-4">
                  <img src={data?.image} alt="imageofBorrower" width={"100px"} height={'90px'} />
                </div>

                <div className="col-lg-2 col-md-2 col-4">
                  <div>{data.name}</div>
                  {/* <div>Grocery Store</div> */}
                </div>
                <div className="col-lg-1 col-md-1 col-4">
                  ${data?.loan} Goal
                </div>
                <div className="col-lg-1 col-md-2 col-4">
                  $ {data?.remain_loan <= 1 ? data?.loan : data?.remain_loan}{" "}
                  Still Needed
                </div>
                <div className="col-lg-2 col-md-2 col-4">
                  <div>Paying {data?.amount}$</div>
                </div>
                <NavLink className="nav-link col-lg-1 col-md-2  col-4 d-flex justify-content-center">
                  <MdDelete
                    onClick={() => deletechart(data?.id)}
                    size={"30px"}
                    color="orange"
                  />
                </NavLink>
              </div>
            </div>
          ))
        ) : (
          <div>No chart found</div>
        )}

        {/* for medium screen */}

        <div className="d-flex  justify-content-end align-items-center gap-3 my-3">
          <div className="fw-bold">
            10% Community Service Partner Contribution
            <br />
            This goes directly to the local partner who helps
            <br />
            ensure responsible lending (more detail)
          </div>
          <div className="fw-bold border p-1 bg-warning">10% Price: 100$</div>
        </div>
        <div className="text-end my-3">
          <p className="fw-bold">Total Price: {totalAmount}$</p>
          <button
            onClick={() => fntData(1)}
            className="btn btn-warning text-white"
          >
            Continue
          </button>
        </div>
      </div>
    </>
  );
};

export default Checkout;
