import React, { useState } from "react";
import { TbArrowsJoin2 } from "react-icons/tb";
import axiosInstance from "../../Axois/Axois";
import toast, { Toaster } from "react-hot-toast";
const SectionTwo = () => {
  const [formData, setFormData] = useState({
    name: "",
    address: "",
    communityBackground: "",
    email: "",
    mobilePhone: "",
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(formData,'data')
    try {
      const { name, address, communityBackground, email, mobilePhone } =
        formData;
      const Datatosend = new FormData();
      Datatosend.append("name", name);
      Datatosend.append("email", email);
      Datatosend.append("phone", mobilePhone);
      Datatosend.append("address", address);
      Datatosend.append("community_background", communityBackground);
      axiosInstance
        .post("/csp-request",Datatosend)
        .then((res) => {
          console.log(res, "check");

          if (res.data.status === true) {
            toast.success(res.data.message);
            setFormData({
              name: "",
              address: "",
              communityBackground: "",
              email: "",
              mobilePhone: "",
            })
          }
        })
        .catch((err) => {
          toast.error(err.message);
        });
    } catch (error) {
      console.log(error, "formData");
    }
  };

  return (
    <div className="container mt-40">
      <Toaster position="top-right" reverseOrder={false} />
      <div className="row mt-40">
        <div className="col-12 text-center">
          <h1>Fill the form to join as a (CSP)</h1>
          <p>Join to help your community</p>
        </div>
        <div className="row justify-content-center gap-3 mb-4">
          <button className="col-lg-2 col-md-4 col-5 btn btn-warning text-light">
            Register
          </button>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6 col-md-12 col-12">
          <TbArrowsJoin2
            style={{ color: "black", padding: "10px" }}
            size={150}
            color="green"
            className="d-flex mx-auto"
          />
          <p>
            {" "}
            To apply to be a CSP, the applicant (You or your team) needs to
            Register.
          </p>
          <p>
            You can then access more details, and then fill out an application
            form – to be a CSP.
          </p>
          <p>One of our team will contact you to discuss the process.</p>
          <p>
            This process ensures your team meet Share-Circle (SC) criteria for
            effectively becoming a community lending Partner.
          </p>
          <p>
            If you do not meet these criteria initially, we may provide some
            recommendations on what your community team needs to do become a CSP
          </p>
        </div>
        <div className="col-lg-6 col-md-12 col-12 py-3">
          <h4 className="text-center">Join as a Community Support Partner</h4>
          <div className="form1232">
            <form onSubmit={handleSubmit}>
              <div>
                <label>Name:</label>
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div>
                <label>Address:</label>
                <input
                  type="text"
                  name="address"
                  value={formData.address}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div>
                <label>Community background (Detailed please)</label>
                <input
                  type="text"
                  name="communityBackground"
                  value={formData.communityBackground}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div>
                <label>Email Address:</label>
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div>
                <label>Mobile Phone:</label>
                <input
                  type="tel"
                  name="mobilePhone"
                  value={formData.mobilePhone}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <button
                style={{
                  backgroundColor: "orange",
                  fontSize: "16px",
                  fontWeight: "600",
                }}
                type="submit"
              >
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionTwo;
