/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
import React, { useRef } from "react";
import { useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import StripeCheckout from "react-stripe-checkout";
import { dispatch } from "../../Auth/store";
import { clearaddtochartlist } from "../../Auth/slice/Addtochart";
import axiosInstance from "../../Axois/Axois";

const AddCartPayment = ({ fntData }) => {
  const [data, setdata] = useState({});
  const [paymentMethod, setPaymentMethod] = useState("stripe");
  const stripeRef = useRef();
  const navigate = useNavigate();
  const tokens = localStorage.getItem("useToken");
  const { items } = useSelector((state) => state.chart);
  const totalAmount = items?.reduce(
    (total, data) => total + parseInt(data?.amount),
    0
  );
  const orderDetail = items?.map((data) => {
    return {
      id: data?.id,
      loan: parseInt(data?.amount),
    };
  });

  const handlechange = (e) => {
    const { name, value } = e.target;
    setdata((pre) => ({
      ...pre,
      [name]: value,
    }));
  };

  const handlesubmit = () => {
    const { name, email, number, city, country } = data;
    if (!tokens) {
      navigate("/login");
    } else if (!name || !email || !number || !city || !country) {
      toast.error("Please fill all the fields");
    } else if (paymentMethod === "stripe") {
      stripeRef?.current?.onClick();
    } else {
      // Call your Zaprite payment function here
      handlesubmits()
    }
  };
  

  const handlesubmits = async (token) => {
    const { name, email, number, city, country } = data;
    // if (!token || !token.id) {
    //   toast.error("Invalid Stripe payment token");
    //   return;
    // }
    const formdata = new FormData();
    formdata.append("name", name);
    formdata.append("city", city);
    formdata.append("contact", number);
    formdata.append("country", country);
    formdata.append("email", email);
    formdata.append("total", totalAmount);
    formdata.append("payment_method", paymentMethod);
    if(paymentMethod == "stripe"){
      formdata.append("source", token.id);
    }
    orderDetail.map((data, index) => {
      formdata.append(`borrowers[${index}][id]`, data.id);
      formdata.append(`borrowers[${index}][loan]`, data.loan);
    });

    await axiosInstance
      .post("/payment", formdata, {
        headers: {
          Authorization: `Bearer ${tokens}`,
        },
      })
      .then((res) => {
        if (res.data.status === true) {
          toast.success(res.data.message);
          dispatch(clearaddtochartlist());
          setdata({
            name: '',
            email: '',
            number: '',
            city: '',
            country: '',
          });
          if(res?.data?.data?.checkoutUrl){
            window.location.href = res?.data?.data?.checkoutUrl
          }
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.message);
      });
  };


  return (
    <>
      <div className="row my-5 gap-5 p-2">
        <Toaster position="top-center" reverseOrder={false} />
        <div className="col-lg-7 col-md-12 col-12 p-4 border" style={{ height: "100%" }}>
          <div className="d-flex justify-content-between align-items-center">
            <div>Contribution Amount:</div>
            <div className="border p-2">${totalAmount}</div>
          </div>
          <div className="fs-5 fw-bold mt-3">Personal info</div>
          <div className="row my-3">
            <div className="col-md-6 col-12">
              <div>Name:</div>
              <input
                className="p-2 my-1 w-100"
                onChange={handlechange}
                name="name"
                value={data?.name}
                placeholder="Enter your name"
              />
            </div>
            <div className="col-md-6 col-12">
              <div>Email:</div>
              <input
                className="p-2 my-1 w-100"
                onChange={handlechange}
                type="email"
                name="email"
                value={data?.email}
                placeholder="Enter your email"
              />
            </div>
          </div>
          <div className="row my-3">
            <div className="col-md-6 col-12">
              <div>Contact#:</div>
              <input
                className="p-2 my-1 w-100"
                onChange={handlechange}
                type="number"
                value={data?.number}
                name="number"
                placeholder="000-1111-0000"
              />
            </div>
            <div className="col-md-6 col-12">
              <div>City Name:</div>
              <input
                className="p-2 my-1 w-100"
                placeholder="Enter your city name"
                onChange={handlechange}
                name="city"
                value={data?.city}
              />
            </div>
          </div>
          <div>
            <div>Country Name:</div>
            <input
              onChange={handlechange}
              name="country"
              value={data?.country}
              className="p-2 my-1 w-100"
            />
          </div>
          <div className="mt-3">
            <div>Payment Method:</div>
            <div className="d-flex">
              <label className="me-3">
                <input
                  type="radio"
                  name="paymentMethod"
                  value="stripe"
                  checked={paymentMethod === "stripe"}
                  onChange={() => setPaymentMethod("stripe")}
                /> Stripe
              </label>
              <label>
                <input
                  type="radio"
                  name="paymentMethod"
                  value="zaprite"
                  checked={paymentMethod === "zaprite"}
                  onChange={() => setPaymentMethod("zaprite")}
                /> Zaprite
              </label>
            </div>
          </div>
          <StripeCheckout
            token={handlesubmits}
            className="d-none"
            currency="USD"
            ref={stripeRef}
            name="Check Out With Stripe"
            description="Payment for your awesome product"
            stripeKey="pk_test_51PE5HcKaUVXW1PTgXh2g06zQsz2E30TJkQljNJ3Yw43W5WGzNP7Ibr9pXXNYx0GRAssHYwPLECTQUiCXEsXJjaMS00KXaSOKKG"
          />
          <div className="d-flex justify-content-between mt-3">
            <button
              className="btn btn-warning text-white px-4"
              onClick={() => fntData(1)}
            >
              Back
            </button>
            <button
              className="btn btn-warning text-white px-4"
              onClick={handlesubmit}
            >
              Submit
            </button>
          </div>
        </div>
        <div className="col-lg-4 col-md-12 col-12 p-0">
          <div className="border mb-3" style={{ height: "auto" }}>
            <div className="d-flex align-items-center bg-warning p-2">
              <div className="fs-6 fw-bold">Donate to: Operational cost</div>
            </div>
            <div className="p-3">
              {/* <image src='./images/allied-bank-logo.png' alt="Allied Bank"  /> */}
              <div className="">Bank: Allied Bank</div>
              <div className="">Account Title: Share-Circle</div>
              <div className="">Account Number: 0010028779120016</div>
              <div className="">IBAN: PK81ABPA0010028779120016</div>
              <div className="">Swift Code: ABPAPKKA0175</div>
              <div className="">Branch Code: 0175</div>
              <div className="">
                Branch: Ladies Branch, 21 - Z - Block, DHA, Lahore Cantt.
              </div>
            </div>
          </div>
          <div className="border mb-3" style={{ height: "auto" }}>
            <div className="d-flex align-items-center bg-warning p-2">
              <div className="fs-6 fw-bold">Donate to: Revolving fund</div>
            </div>
            <div className="p-3">
              <div className="">Bank: Mezaan Bank</div>
              <div className="">Account Title: Share-Circle</div>
              <div className="">Account Number: 0010028779120016</div>
              <div className="">IBAN: PK81ABPA0010028779120016</div>
              <div className="">Swift Code: ABPAPKKA0175</div>
              <div className="">Branch Code: 0175</div>
              <div className="">
                Branch: Ladies Branch, 21 - Z - Block, DHA, Lahore Cantt.
              </div>
            </div>
          </div>
        </div>
        <div></div>
      </div>
    </>
  );
};

export default AddCartPayment;
