import React from 'react'
import Faq from '../component/Faq/Faq'
const FaqPage = () => {
  return (
    <div>
        <Faq/>
    </div>
  )
}

export default FaqPage