import React, { useEffect } from "react";
import SectionOne from "../component/homecomponent/SectionOne";
import SectionThree from "../component/homecomponent/SectionThree";
import SectionTwo from "../component/homecomponent/SectionTwo";
import HomeBanner from "../component/homecomponent/HomeBanner";
const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0)

  }, [])
  return (
    <>
      <HomeBanner />
      <SectionOne />
      <SectionTwo />
      <SectionThree />
    </>
  );
};

export default Home;
