import React, { useEffect, useState } from 'react'
import { BsFacebook, BsLinkedin } from "react-icons/bs";
import { AiFillTwitterCircle } from "react-icons/ai";
import { RiInstagramFill } from "react-icons/ri";
import { NavLink } from 'react-router-dom';
import Testimonial from '../../TestimonialCards';
// import { BiDetail } from 'react-icons/bi';
// import Testimonial from '../TestimonialCards';
// import { Flat, Heat, Nested } from '@alptugidin/react-circular-progress-bar'

const Profile1 = () => {

    const [details, setDetails] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0)

    }, [])
    return (
        <div className='container' style={{ marginTop: '100px' }}>
            <div className='row pt-5'>
                <div className='col-lg-6 col-md-12 col-12'>
                    <h2 className='fw-bold'>Raquel Martinez</h2>
                    <div className='d-flex gap-2 my-2'>
                        <span>
                            <BsFacebook color="#3b5998" size={"35px"} />
                        </span>
                        <span>
                            <BsLinkedin color="#0072b1" size={"35px"} />
                        </span>
                        <span>
                            <AiFillTwitterCircle color="#0072b1" size={"40px"} />
                        </span>
                        <span>
                            <RiInstagramFill color="#E1306C" size={"40px"} />
                        </span>
                    </div>

                    <div className='d-flex gap-5 align-items-center my-2'>
                        <div style={{ fontSize: "15px" }}>
                            <ul className='mx-0 px-0' style={{listStyle:"none"}}>
                                <li><strong className="fw-bold">Loan: </strong>$352</li>
                                <li><strong className="fw-bold">Repayment schedule: </strong>Monthly</li>
                            </ul>
                        </div>
                        <div style={{ fontSize: "15px" }}>
                            <ul className='mx-0 px-0' style={{listStyle:"none"}}>
                                <li><strong className="fw-bold">Per month: </strong>14$</li>
                                <li><strong className="fw-bold">Loan Length: </strong>25 Months</li>
                            </ul>
                        </div>
                    </div>
                    
                    <div className='my-2'> <label className=''>A loan of $352 helps to restock a woman-owned cosmetic business.</label></div>
                    <div className='my-2'> <div className='fw-bold'>About Me:</div>
                        Raquel always aspired to work for herself, and she started her business by selling cosmetics to her neighbours and acquaintances in her area. In 2018, with the dream of having her own store, she decided to create a space for attending to customers in the garage of her house, but her sales fell with the arrival of the pandemic and this prevented her business from growing. She didn't give up on her dream with all the difficulties, and she is still working to grow her business. She will invest in buying shampoos, creams, makeup, soap, and moisturizers.
                    </div>
                    <div className=''>
                        <NavLink to="" className='btn btn-warning my-3 text-white'>Donate</NavLink>
                    </div>
                </div>
                <div className='col-lg-6 col-md-12 col-12 d-flex justify-content-center align-items-center imgprofile'>
                    <img className='mx-auto' src='./images/enterpreniur-cart1.jpg' height={'400px'} alt='imageaw' />
                </div>
            </div>
            <div className='text-center my-4'>
                <NavLink className="text-warning" onClick={() => setDetails(!details)}>More details</NavLink>
            </div>
            {details ? <div>
                <div className='row my-1'>
                    <p>My interests encompass diverse activities such as travelling, immersing myself in business magazines, participating in both physical and online business forums, attending workshops and engaging in group discussions. Moreover, I find fulfilment in my visits to children's homes and hold a profound passion for corporate social responsibility.</p>
                    <p>My involvement in business forums has proven invaluable in equipping me with the skills needed to address the various challenges that arise in day-to-day operations. I recognise the significance of people as a valuable resource, pivotal to navigating the intricate landscape of business, and this realisation has contributed greatly to my learning journey.</p>
                    <p>Among the lending organisations I've come across, Share-Circle stands out as a great choice. With rates that are not only pocket-friendly but also exceptionally affordable for newcomers and those seeking to expand their business inventory.</p>
                    <p>Anticipating a productive and harmonious partnership, I am enthusiastic about the prospect of collaborating with Share-Circle. With our combined efforts, I am confident in our ability to achieve success and navigate a seamless path forward.</p>
                </div>
                <div className='row my-3'>
                    <div className='fw-bold'>My Business</div>
                    <p>Last year, I launched my clothing shop focusing on women's apparel, handbags, shoes, and casual wear for kids. This venture has emerged as my primary source of income, enabling me to meet all my financial obligations. The positive feedback from my clients often results in referrals, driven by the authenticity of the products I offer. Through strategic sourcing from Turkey and China, I maintain cost-effective pricing, which contributes to a growing and loyal customer base.</p>
                    <p>A notable feature of my business is the convenience I provide to my clients. Operating both an online store and a physical shop, I ensure accessibility. Additionally, my delivery services have gained popularity among my clients. This flexibility caters to busy individuals who can receive their orders at their preferred time and location, whether it be their office or home.</p>
                    <p>Running my business has proven profitable due to these factors. Operating expenses, including a monthly rent of 30,000 shillings and utility bills, are balanced against my robust sales. Yet, like any venture, risks are part of the equation. Occasionally, items delivered to online clients might not fit as expected, leading to exchanges. Furthermore, navigating unfamiliar locations during deliveries can pose challenges due to unclear directions.</p>
                    <p>With monthly sales ranging from KES 150,000 to 180,000, and after deducting necessary expenses, I typically achieve a profit margin of KES 50,000 to 70,000. I have secured a loan that I intend to repay through my profits. As I plan to expand my stock, I am confident that my profits will experience a positive trajectory in the coming months.</p>
                </div>
            </div> : null}
            <div className='text-center fs-1 fw-bold my-3'>See success stories</div>
            <Testimonial />
        </div>
    )
}

export default Profile1