import React, { useEffect } from 'react'
import HomeBanner from '../component/homecomponent/HomeBanner'
import SectionOne from '../component/CSPcomponent/SectionOne'
import SectionTwo from '../component/CSPcomponent/SectionTwo'
const CSP = () => {
  
  useEffect(() => {
    window.scrollTo(0, 0)

  }, [])
  return (
    <div>
        <HomeBanner/>
        {/* <h1 className="text-dark text-center" style={{marginTop:'130px'}}>This is Community service Provider Page</h1> */}

        <SectionOne />
      <SectionTwo />
      {/* <SectionThree /> */}
    </div>
  )
}

export default CSP