/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";
import { FaHandHoldingDollar } from "react-icons/fa6";
import axiosInstance from "../../Axois/Axois";
import toast, { Toaster } from "react-hot-toast";
// import { NavLink } from "react-router-dom";

const SEctionTwo = () => {
  const [country, setcountry] = useState([]);
  const [csp, setcsp] = useState([]);
  const [cspid, setCspid] = useState('');
  const [formData, setFormData] = useState({
    fname: "",
    lname: "",
    contact: "",
    email: "",
    requirement: "",
  });
  useEffect(() => {
    axiosInstance
      .get("/all/country")
      .then((res) => {
        setcountry(res?.data?.data);
      })
      .catch((err) => {
        toast.error(err.message);
      });
  }, []);
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handlegetcsp = async (e) => {
    const { value } = e.target;
    await axiosInstance
      .get(`/all/csp?country=${value}`)
      .then((res) => {
        if (res.data.status === true) {
          setcsp(res.data.data);
          toast.success(res.data.message);
        }
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };
  const handleSubmit = async (event) => {
    console.log(cspid,'cspid');
    event.preventDefault();
    try {
      const fromdatatosend = new FormData();
      fromdatatosend.append("fname", formData.fname);
      fromdatatosend.append("lname", formData.lname);
      fromdatatosend.append("phone", formData.contact);
      fromdatatosend.append("email", formData.email);
      fromdatatosend.append("business", formData.requirement);
      fromdatatosend.append("csp_id", cspid);
      await axiosInstance
        .post("/borrower-request", fromdatatosend)
        .then((res) => {
          if (res.data.status === true) {
            toast.success(res.data.message);
            setFormData({
              fname: "",
              lname: "",
              contact: "",
              email: "",
              requirement: "",
            });
          }
        }).catch((err)=>{
          toast.error(err.message);

        })
    } catch (error) {
      console.log(error)
    }
  };
  return (
    <div className="container mt-40">
      <Toaster position="top-right" reverseOrder={false} />
      <h1 className="text-center">Register to apply for a loan</h1>
      <div className="row justify-content-center gap-3">
        <button className="col-lg-2 col-md-4 col-5 btn btn-warning text-light">
          Register
        </button>
      </div>
      <div className="row py-4">
        <div className="d-flex flex-column justify-content-center align-items-center col-lg-6 col-md-6 ">
          <div className="col-12 d-flex justify-content-center align-items-center">
            <FaHandHoldingDollar size={120} color="black" />
          </div>
          <p className="mt-4">
            On the entrepreneur profiles page, you will meet small business
            starters or owners, who have demonstrated to their local Community
            Service Partner (CSP) that they have a genuine business need and a
            viable plan to repay their business loan to the Share-Circle
            Community Lending Platform
          </p>
          <p>
            As these funds are repaid, they become available for re-investment
            or re-lending to other business entrepreneurs.
          </p>
          <p>
            So as a responsible borrower, repayment of your loan, will continue
            to support others by being lent out again, as part of a “Fund raise”
            for someone else.
          </p>
        </div>
        <div className="col-lg-6  col-md-6 col-12">
          <h6 className="mx-auto mb-3 fw-bold text-center">
            Please select from the registered CSP/ country list
          </h6>
          <div className="col-9 mx-auto">
            <select
              onChange={(e) => handlegetcsp(e)}
              className=" col-12 px-3 rounded"
              style={{ height: "50px" }}
            >
              <option >select Country</option>
              {country?.map((data, index) => {
                return (
                  <>
                    <option key={index}>{data.country}</option>
                  </>
                );
              })}
            </select>
            <select
              className=" col-12 px-3 mt-3 rounded"
              style={{ height: "50px" }}
              onChange={(e) => {
                const selectedValue = e.target.value;
                setCspid(selectedValue);
              }}
            >
              <option  >First select country</option>
              {csp?.map((data, index) => {
                return (
                  <>
                    <option value={data.id} key={index}>{data.name}</option>
                  </>
                );
              })}
            </select>
          </div>
          <div className="form1232 my-5">
            <form onSubmit={handleSubmit}>
              <div>
                <label>First Name:</label>
                <input
                  type="text"
                  name="fname"
                  value={formData.fname}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div>
                <label>Last Name:</label>
                <input
                  type="text"
                  name="lname"
                  value={formData.lname}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div>
                <label>Phone No:</label>
                <input
                  type="text"
                  name="contact"
                  value={formData.contact}
                  onChange={handleInputChange}
                  required
                />
              </div>

              <div>
                <label>Email:</label>
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div>
                <label>Business:</label>
                <input
                  type="text"
                  name="requirement"
                  value={formData.requirement}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <button
                style={{
                  backgroundColor: "orange",
                  fontSize: "16px",
                  fontWeight: "600",
                }}
                type="submit"
              >
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SEctionTwo;
