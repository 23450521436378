import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { RiShareForwardFill } from 'react-icons/ri';
import toast, { Toaster } from 'react-hot-toast';
import { useEffect } from "react";
import axiosInstance from "../../Axois/Axois";

const Faq = () => {
  const [messagedata, setmessagedata] = useState([]);
  const [messageBox, setMessageBox] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const messageFunt = async () => {
    if(messagedata === ''){
      toast.error("Please enter your Question")
    }else{
      try {
        const formData = new FormData();
        formData.append("message", messagedata)
        const response = await axiosInstance.post("/message", formData);
        if (response?.data?.status === true) {
          toast.success(response.data.message)
        setmessagedata("")

        }
      }
      catch (error) {
        setmessagedata("")
        toast.error(error.message)
      }
    }
   
  }

  const handleMessage = (e) => {
    e.preventDefault();
    messageFunt()
  }

  return (
    <>
      <Toaster
        position="top-right"
        reverseOrder={false}
      />
      <div className="container mt-150">
        <div className="row mt-40">
          <div className="col-12 text-center">
            <h1>Frequently Asked Questions</h1>
            <p>Feel free to ask any questions</p>
          </div>
        </div>
        <div className="row mt-40 mb-5">
          <div class="accordion accordion-flush" id="accordionFlushExample">
            <div class="accordion-item">
              <h2 class="accordion-header" id="flush-headingOne">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseOne"
                  aria-expanded="false"
                  aria-controls="flush-collapseOne"
                >
                  Why the focus on Community Partners?
                </button>
              </h2>
              <div
                id="flush-collapseOne"
                class="accordion-collapse collapse"
                aria-labelledby="flush-headingOne"
                data-bs-parent="#accordionFlushExample"
              >
                <div class="accordion-body">
                  Locally motivated CSPs with a clear remuneration policy realise that they can get paid for their time and also help others. The CSP is essentially a self-employed, franchise-type model that enables them to earn some money by facilitating support and responsible community-based lending. They earn some money for their time, helping themselves and their community.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="flush-headingTwo">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseTwo"
                  aria-expanded="false"
                  aria-controls="flush-collapseTwo"
                >
                  Why should donors allocate an extra 10%?
                </button>
              </h2>
              <div
                id="flush-collapseTwo"
                class="accordion-collapse collapse"
                aria-labelledby="flush-headingTwo"
                data-bs-parent="#accordionFlushExample"
              >
                <div class="accordion-body">
                  Research shows that many donors are skeptical about where their money goes and how much goes to intermediary parties. In this model the Community Support Partner provides a valuable service, and ensures responsible lending and borrowing. Hence this 10% is fixed, to be very transparent and clear where the money goes. This is paid to the CSP as repayments come back into the system: It enables the service to have fixed costs, be largely automated, and thus clear for Donors, borrowers and community partners.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="flush-headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseThree"
                  aria-expanded="false"
                  aria-controls="flush-collapseThree"
                >
                  Why is the rate 0% to the borrower?
                </button>
              </h2>
              <div
                id="flush-collapseThree"
                class="accordion-collapse collapse"
                aria-labelledby="flush-headingThree"
                data-bs-parent="#accordionFlushExample"
              >
                <div class="accordion-body">
                  Since this model is built on reusing donations, we can offer a 0% interest rate to borrower. They get the clarity of an agreed loan duration and a fixed monthly payment. Since all repaid funds go back into the core platform, and get recirculated in the system, it allows us to offer this rate. Hence the only leakage is defaults or similar type lost repayments.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="flush-headingfour">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapsefour"
                  aria-expanded="false"
                  aria-controls="flush-collapsefour"
                >
                  Are there ways for the borrower to be even more efficient?
                </button>
              </h2>
              <div
                id="flush-collapsefour"
                class="accordion-collapse collapse"
                aria-labelledby="flush-headingfour"
                data-bs-parent="#accordionFlushExample"
              >
                <div class="accordion-body">
                  “Pay me by lightning”<br />
                  Yes, there is a clear recommendation to businesses to ask to receive customer payments (where possible) in Bitcoin or Tether (but they can receive their normal currency.) If they do this, they can simply use those funds in Bitcoin or Tether funds to repay their loan via their phone on a monthly basis.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="flush-headingfive">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapsefive"
                  aria-expanded="false"
                  aria-controls="flush-collapsefive"
                >
                  Any technology specific requirements?
                </button>
              </h2>
              <div
                id="flush-collapsefive"
                class="accordion-collapse collapse"
                aria-labelledby="flush-headingfive"
                data-bs-parent="#accordionFlushExample"
              >
                <div class="accordion-body">
                  The borrower or family member must have access to a smart phone.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="flush-headingsix">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapsesix"
                  aria-expanded="false"
                  aria-controls="flush-collapsesix"
                >
                  How should loan payments be repaid?
                </button>
              </h2>
              <div
                id="flush-collapsesix"
                class="accordion-collapse collapse"
                aria-labelledby="flush-headingsix"
                data-bs-parent="#accordionFlushExample"
              >
                <div class="accordion-body">
                  Regular loan repayments must be in BTC or Tether. This is very simple, via lightning, on an app on your phone.              </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="flush-headingseven">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseseven"
                  aria-expanded="false"
                  aria-controls="flush-collapseseven"
                >
                  How much can I borrow?
                </button>
              </h2>
              <div
                id="flush-collapseseven"
                class="accordion-collapse collapse"
                aria-labelledby="flush-headingseven"
                data-bs-parent="#accordionFlushExample"
              >
                <div class="accordion-body">
                  First time loans usually range from $50 to $400, depending on the quality of your application and supporting documents. After a successful period and working with your CSP, you can borrow more, depending on your repayment record and following the processes. These loans can initially rise up to $3000.   (and more for larger teams)
                </div>
              </div>
            </div>

          </div>

          <div className="mt-5">
            <p className="text-center">
              <b>Note:</b> If these do not answer your questions, please submit your question via the form on the <NavLink className="text-warning" onClick={() => setMessageBox(!messageBox)}>Contact Us <RiShareForwardFill /></NavLink> page
            </p>
            <div className="text-center">


              {messageBox ?
                <div className="my-3">
                  <form onSubmit={handleMessage}>
                    <div class="form-floating col-lg-5 mx-auto">
                      <textarea onChange={(e)=>setmessagedata(e.target.value)} class="form-control" placeholder="Leave a comment here" id="floatingTextarea"></textarea>
                      <label for="floatingTextarea">Please submit your question</label>
                    </div>
                    <button className="btn btn-warning text-white mt-3" type="submit">Submit Question</button>
                  </form>
                </div> : null}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Faq;
