import React from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import { AiOutlineArrowUp, AiFillStar } from "react-icons/ai";
import { BiSearch } from "react-icons/bi";
import { Link, NavLink, useLocation } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import { BiSolidMessageRoundedEdit } from "react-icons/bi";
import { FaDonate, FaBitcoin, FaBell } from "react-icons/fa";
import { RiFundsBoxFill, RiCommunityFill } from "react-icons/ri";
import { MdOutlineRequestPage } from "react-icons/md";
import { FaHandHoldingDollar } from "react-icons/fa6";

import { BsLightningFill } from "react-icons/bs";
import { AiFillHeart } from "react-icons/ai";
import { useSelector } from "react-redux";
const Header = () => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const location = useLocation();

  const {items} = useSelector((state)=>state.chart)
  const handleScroll = () => {
    const position = window.scrollY;
    setScrollPosition(position);
  };

  useEffect(() => {
    // Attach the scroll event listener when the component mounts
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  return (
    <>
      <div className="container-fluid Nav-main bg-dark position-fixed">
        <div className="container nav-content">
          <nav class="navbar navbar-expand-lg ">
            <div>
              <Link to={"/"}>
                <img src="../images/logo1.png" alt="logo" className="logo" />
              </Link>
            </div>

            <div class="collapse navbar-collapse" id="navbarNav">
              <ul class="navbar-nav text-white">
                <li class="nav-item ">
                  <Link className={location.pathname === '/CSP' ? 'nav-link active-color' : 'nav-link'} aria-current="page" to={'/CSP'}>
                    Community Partner <span style={{ opacity: "0" }}>a</span><span style={{ color: "orange" }}>|</span>
                  </Link>
                </li>
                <li class="nav-item">
                  <Link className={location.pathname === '/Entrepreneur' ? 'nav-link active-color' : 'nav-link'} to={'/Entrepreneur'}>
                    Borrow <span style={{ opacity: "0" }}>a</span><span style={{ color: "orange" }}>|</span>
                  </Link>
                </li>
                <li class="nav-item">
                  <Link className={location.pathname === '/donate' ? 'nav-link active-color' : 'nav-link'} to={'/donate'}>
                    Donate <span style={{ opacity: "0" }}>a</span><span style={{ color: "orange" }}>|</span>
                  </Link>
                </li>
              </ul>
            </div>

          </nav>

          <div className="Toggle d-flex gap-5">
            <NavLink to={'/addtocart'} className="position-relative">
              <div className="rounded-circle fw-bold d-flex justify-content-center align-items-center px-2 py-0" style={{ position: "absolute", bottom: "16px", left: "13px", backgroundColor: 'white', color: "black" }}>{items?.length}</div>
              <FaBell color="orange" size={"35px"} />
            </NavLink>
            <div className="d-lg-block d-md-none d-none position-relative">
              <input className="py-1" style={{ paddingLeft: '10px', paddingRight: "5px", border: "2px solid orange", borderRadius: '8px' }} />
              <BiSearch style={{ position: "absolute", right: '10px', top: "7px", color: "orange", fontSize: "25px", fontWeight: "600", cursor: "pointer" }} />
            </div>

            <GiHamburgerMenu
              size={"32"}
              type="button"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasRight"
              aria-controls="offcanvasRight"
            />
          </div>
        </div>

        {/* -----------------------offcanvassss------------------------------ */}
        <div
          class="offcanvas offcanvas-end "
          style={{ backgroundColor: 'gainsboro', width: "320px" }}
          tabindex="-3"
          id="offcanvasRight"
          aria-labelledby="offcanvasRightLabel"
        >
          <div class="offcanvas-header">
            <NavLink to={'/'} id="offcanvasRightLabel" className="nav-link fw-bold">About Us</NavLink>
            <button
              type="button"
              class="btn-close text-reset"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            ></button>
          </div>
          <div class="offcanvas-body">
            <div className="d-lg-none d-md-flex justify-content-center position-relative my-2">
              <input className="col-12 py-2" style={{ paddingLeft: '10px', paddingRight: "5px", border: "2px solid orange", borderRadius: '8px' }} />
              <BiSearch style={{ position: "absolute", right: '10px', top: "10px", color: "orange", fontSize: "25px", fontWeight: "600", cursor: "pointer" }} />
            </div>
            <ul
              className="list-items11221"
              style={{
                listStyle: "none",
                textDecoration: "none",
                padding: "0px",
              }}
            >
              <div className="d-lg-none d-md-block d-block position-relative">
                <li>
                  <AiFillStar
                    style={{
                      color: "orange",
                      marginRight: "14px",
                      fontSize: "30px",
                    }}
                  />{" "}
                  <Link style={{ textDecoration: 'none', color: "black" }} to={'/donate'}>Donate Now</Link>
                </li>
              </div>
              <div className="d-lg-none d-md-block d-block position-relative">
                <li>
                  <RiCommunityFill
                    style={{
                      color: "orange",
                      marginRight: "14px",
                      fontSize: "30px",
                    }}
                  />{" "}
                  <Link style={{ textDecoration: 'none', color: "black" }} to={'/CSP'}>Community Partner</Link>
                </li>
              </div>
              <div className="d-lg-none d-md-block d-block position-relative">
                <li>
                  <FaHandHoldingDollar
                    style={{
                      color: "orange",
                      marginRight: "14px",
                      fontSize: "30px",
                    }}
                  />{" "}
                  <Link style={{ textDecoration: 'none', color: "black" }} to={'/Entrepreneur'}>Borrow</Link>
                </li>
              </div>

              <li>
                <BiSolidMessageRoundedEdit
                  style={{
                    color: "orange",
                    marginRight: "14px",
                    fontSize: "30px",
                  }}
                />{" "}
                <Link style={{ textDecoration: 'none', color: "black" }} to={'/Faq'}>FAQs</Link>
              </li>
              <li>
                <FaDonate
                  style={{
                    color: "orange",
                    marginRight: "20px",
                    fontSize: "30px",
                  }}
                />
                <Link style={{ textDecoration: 'none', color: "black" }} to={'/donate'}>Donors</Link>
              </li>
              <li>
                <RiFundsBoxFill
                  style={{
                    color: "orange",
                    marginRight: "20px",
                    fontSize: "30px",
                  }}
                />
                <Link style={{ textDecoration: 'none', color: "black" }} to={'/CSP'}>CSPs</Link>
              </li>
              <li>
                <MdOutlineRequestPage
                  style={{
                    color: "orange",
                    marginRight: "20px",
                    fontSize: "30px",
                  }}
                />
                <Link style={{ textDecoration: 'none', color: "black" }} to={'/Entrepreneur'}>Entrepreneurs</Link>
              </li>
              <li>
                <BsLightningFill
                  style={{
                    color: "orange",
                    marginRight: "20px",
                    fontSize: "30px",
                  }}
                />
                <Link style={{ textDecoration: 'none', color: "black" }} to={"/lightning"}>Lightning</Link>

              </li>
              <li>
                <FaBitcoin
                  style={{
                    color: "orange",
                    marginRight: "20px",
                    fontSize: "30px",
                  }}
                />
                <Link style={{ textDecoration: 'none', color: "black" }} to={"/lightning"}>Resources </Link>

              </li>
            </ul>
          </div>
        </div>

        {/* --------------------togotop--------------------------- */}
        {scrollPosition > 500 ? (
          <button
            class="btn btn-sm p-2 rounded-circle position-fixed bottom-0 end-0 translate-middle "
            style={{ backgroundColor: "orange", color: "white" }}
            onClick={() => scrollToTop()}
            id="back-to-up"
          >
            <AiOutlineArrowUp style={{ fontWeight: "bold" }} size={35} title="Go To Top" />
          </button>
        ) : null}
      </div>
      <div style={{ position: "fixed", top: "92vh", left: "40px", fontSize: "18px", fontWeight: "600", zIndex: "11233", width: "190px", color: "white", backgroundColor: "orange", padding: '8px', borderRadius: "10px" }}>
        <Link to={'/donate'} style={{ color: 'white', textDecoration: 'none' }} className="d-flex justify-content-center align-items-center gap-2"> <AiFillHeart /> Donate Today</Link>
      </div>
    </>
  );
};

export default Header;
