import React from "react";

import ReviewDonate from "./REVIEW & DONATE";
import Checkout from "./checkout";
// import Guest from './Guest';
import { NavLink } from "react-router-dom";
import { useState } from "react";
import AddCartPayment from "./AddCartPayment";

const AddtoCart = () => {
  const [clibtn, setclibtn] = useState("");
  const fntData = (ind) => {
    setclibtn(ind);
  };
  console.log(clibtn, "clibtn");
  return (
    <div className="container" style={{ marginTop: "130px" }}>
      <div className="row">
        <div className="d-flex gap-5 text-center bg-warning p-2">
          <NavLink
            className={
              clibtn === 1 || clibtn === 2
                ? "nav-link text-addtocart-heading "
                : "nav-link text-addtocart-heading text-white"
            }
          >
            My basket
          </NavLink>
          <NavLink
            className={
              clibtn === 1
                ? "nav-link text-addtocart-heading text-white"
                : "nav-link text-addtocart-heading"
            }
          >
            Review & donate
          </NavLink>
          <NavLink
            className={
              clibtn === 2
                ? "nav-link text-addtocart-heading text-white"
                : "nav-link text-addtocart-heading"
            }
          >
            Thank you
          </NavLink>
        </div>
      </div>

      {clibtn === 1 || clibtn === 2 ? null : <Checkout fntData={fntData} />}
      {clibtn === 1 ? <ReviewDonate fntData={fntData} /> : null}
      {clibtn === 2 ? <AddCartPayment fntData={fntData} /> : null}
      {/* <Guest /> */}
    </div>
  );
};

export default AddtoCart;
