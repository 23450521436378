import React, { useEffect } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Home from './pages/Home'
import Header from './GlobalComponent/Header/Header'
import CSP from './pages/CSP'
import './App.css'
import Footer from './GlobalComponent/Footer/Footer'
import FaqPage from './pages/FaqPage'
import Entrepreneur from './pages/Entrepreneur'
import Lightining from './pages/Lightining'
import Donate from './pages/Donates'
import ProfileDetailes from './pages/Donate/ProfileDetailes'
import Security from './component/Security&Trust/Security'
import AddtoCart from './pages/Donate/AddtoCart'
import Guest from './pages/Donate/Guest'
import Profile1 from './pages/Donate/Profilepages/Profile1'
import Profile2 from './pages/Donate/Profilepages/Profile2'
import Profile3 from './pages/Donate/Profilepages/Profile3'
import Loanform from './component/ApplyforLoan/Loanform'
import Login from './Auth/form/login'
import Signup from './Auth/form/signup'
import Form2 from './component/ApplyforLoan/form2'
const App = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
    console.log("dsds")
      window.history.scrollRestoration="manual"
    }, [])
  return (
    <>
      <BrowserRouter>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/CSP" element={<CSP />} />
          <Route path="/Faq" element={<FaqPage />} />
          <Route path='/Entrepreneur' element={<Entrepreneur />} />
          <Route path='/lightning' element={<Lightining />} />
          <Route path='/donate' element={<Donate />} />
          <Route path='/profileDetail/:id' element={<ProfileDetailes />} />
          <Route path='/security' element={<Security />} />
          <Route path='/addtocart' element={<AddtoCart />} />
          <Route path='/guest' element={<Guest />} />
          <Route path='/profile1' element={<Profile1 />} />
          <Route path='/profile2' element={<Profile2 />} />
          <Route path='/profile3' element={<Profile3 />} />
          <Route path='/loanform' element={<Loanform />} />
          <Route path='/login' element={<Login />} />
          <Route path='/form2' element={<Form2 />} />
          <Route path='/signup' element={<Signup />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </>
  )
}

export default App