import { createSlice } from "@reduxjs/toolkit";
import toast from "react-hot-toast";

const initialState = {
  items: [],
};

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addItem: (state, action) => {
      const newItem = action.payload;
      const existingItem = state.items.findIndex((item) => item.id === newItem.id);
      if (existingItem !== -1) {
        // If the item with the same id exists, update its amount
        state.items[existingItem].amount = newItem.amount;
        toast.success('Fund amount updated');
      } else {
        // If the item doesn't exist, add it to the state
        state.items.push(newItem);
        toast.success('Borrower added to the cart');
      }
  
    },
    removeItem: (state, action) => {
      const itemId = action.payload;
      state.items = state.items.filter((item) => item.id !== itemId);
    },
    clearaddtochartlist: (state) => {
      state.items = [];
    },
    updateCartItemQuantity: (state, action) => {
      const { id } = action.payload;
      const itemIndex = state.items.findIndex(item => item.id === id);
      if (itemIndex !== -1) {
        state.items[itemIndex].amount += 1;
      }
    },
    decreaseCartItemQuantity: (state, action) => {
      const { id } = action.payload;
      const itemIndex = state.items.findIndex(item => item.id === id);
      if (itemIndex !== -1) {
        if(state.items[itemIndex].qty === 1){
          toast.error('Item Must be Greater Then 1')
        }else{

          state.items[itemIndex].qty -= 1;
        }
      }
    }
  },
});

export const { addItem, removeItem, updateCartItemQuantity,clearaddtochartlist,decreaseCartItemQuantity } = cartSlice.actions;

export default cartSlice.reducer;
