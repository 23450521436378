import React from 'react'
import { FaCircleDollarToSlot, FaBitcoin } from 'react-icons/fa6';
import { PiCircleHalfLight } from 'react-icons/pi';
import { CgCommunity } from 'react-icons/cg';
import { MdOutlineSocialDistance } from 'react-icons/md';
import { BsCurrencyBitcoin } from 'react-icons/bs';

const SectionThree = () => {
  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <h1 className="section-title pt-5 text-center">
            Why is Share-Circle different?
          </h1>
        </div>
      </div>
      <div class="row my-3 justify-content-center">

        {/* <!-- Example Card One --> */}
        <div class="col-md-6 col-lg-4 col-12   d-flex justify-content-center">
          <div class="card customStyle-card-dynamic">
            <CgCommunity style={{ margin: "2px auto", fontSize:"50px" }} />
            <h5 class="card-title customStyle-card-title-dynamic">COMMUNITY SERVICE PARTNERS AT THE CENTRE</h5>
            <div class="card-body customStyle-card-body-dynamic">
              <p class="card-text customStyle-card-text-dynamic">  Having community partners at the centre. Most other microfinance
                lenders use larger established Community partners or NGOs with
                associate overheads to facilitate (intermediate) and service their
                loans.
              </p>
            </div>
          </div>
        </div>

        {/* <!-- Example Card Two --> */}
        <div class="col-md-6 col-lg-4 col-12 d-flex justify-content-center">
          <div class="card customStyle-card-dynamic">
            <PiCircleHalfLight style={{ margin: "2px auto", fontSize:"50px" }}/>
            <h5 class="card-title customStyle-card-title-dynamic">FULL TRANSPARENCY ON FUNDS USE</h5>
            <div class="card-body customStyle-card-body-dynamic">
              <p class="card-text customStyle-card-text-dynamic"> Full transparency on funds use. Clarity on how funds are
                recirculated, thus more entrepreneurs supported.
              </p>
            </div>
          </div>
        </div>

        {/* <!-- Example Card Three --> */}
        <div class="col-md-6 col-lg-4 col-12 d-flex justify-content-center">
          <div class="card customStyle-card-dynamic">
            <FaCircleDollarToSlot style={{ margin: "2px auto", fontSize:"50px" }} />
            <h5 class="card-title customStyle-card-title-dynamic">0% INTEREST RATES</h5>
            <div class="card-body customStyle-card-body-dynamic">
              <p class="card-text customStyle-card-text-dynamic"> 0% interest rates, with repayment of funds back into the core
                system, enable the core account to continue to grow</p>
            </div>
          </div>
        </div>
        {/* <!-- Example Card four --> */}
        <div class="col-md-6 col-lg-4 col-12 d-flex justify-content-center">
          <div class="card customStyle-card-dynamic">
            <FaBitcoin style={{ margin: "2px auto", fontSize:"50px" }} />
            <h5 class="card-title customStyle-card-title-dynamic">BITCOIN AND LIGHTNING NETWORK</h5>
            <div class="card-body customStyle-card-body-dynamic">
              <p class="card-text customStyle-card-text-dynamic"> With Bitcoin and Lightning the system helps users
                eliminate/minimise financial intermediary costs.</p>
            </div>
          </div>
        </div>

        {/* <!-- Example Card five --> */}
        <div class="col-md-6 col-lg-4 col-12 d-flex justify-content-center">
          <div class="card customStyle-card-dynamic">
            <BsCurrencyBitcoin style={{ margin: "2px auto", fontSize:"50px" }} />
            {/* <img className='text-white' src='./images/bitcoin-communicate.png' alt='bitcoin-communicate' width={'60px'}/> */}
            <h5 class="card-title customStyle-card-title-dynamic">Bitcoin Friendly Communities</h5>
            <div class="card-body customStyle-card-body-dynamic">
              <p class="card-text customStyle-card-text-dynamic"> This service is designed and intended to facilitate building and growing small businesses in Bitcoin circular economies. Donations are long term investments into these communities.</p>
            </div>
          </div>
        </div>
        {/* <div class="col-md-6 col-lg-4 col-12 d-flex justify-content-center">
          <div class="card customStyle-card-dynamic">
            <TbScaleOutline style={{ margin: "2px auto", fontSize:"50px" }} />
            <h5 class="card-title customStyle-card-title-dynamic">SCALABLE AGGREGATION SYSTEM</h5>
            <div class="card-body customStyle-card-body-dynamic">
              <p class="card-text customStyle-card-text-dynamic">The platform has scope to be a scalable crowdfunding-microfinance
                aggregation system that enables any community group / lending
                partner to “Advertise / crowdfund” on this platform***</p>
            </div>
          </div>
        </div> */}

        {/* <!-- Example Card six --> */}
        <div class="col-md-6 col-lg-4 col-12 d-flex justify-content-center">
          <div class="card customStyle-card-dynamic">
            <MdOutlineSocialDistance style={{ margin: "2px auto", fontSize:"50px" }} />
            <h5 class="card-title customStyle-card-title-dynamic">STANDARDISATION AND CLEAR CAP</h5>
            <div class="card-body customStyle-card-body-dynamic">
              <p class="card-text customStyle-card-text-dynamic">Standardisation and clear cap on what community partners get paid
                for being loan broker-partners Its like a global “Loan Franchise
                model” for accredited community groups</p>
            </div>
          </div>
        </div>

      </div>
      {/* <div className="row">
      <div className="col-12">
        <h6 className="section-title mt-4">
          ShareCircle differentiates itself by :
        </h6>
        <ul>
          <li>
            Having community partners at the centre. Most other microfinance
            lenders use larger established Community partners or NGOs with
            associate overheads to facilitate (intermediate) and service their
            loans.
          </li>
          <li>
            Full transparency on funds use. Clarity on how funds are
            recirculated, thus more entrepreneurs supported.
          </li>
          <li>
            0% interest rates, with repayment of funds back into the core
            system, enable the core account to continue to grow
          </li>
          <li>
            With Bitcoin and Lightning the system helps users
            eliminate/minimise financial intermediary costs.
          </li>
          <li>
            The platform has scope to be a scalable crowdfunding-microfinance
            aggregation system that enables any community group / lending
            partner to “Advertise / crowdfund” on this platform***
          </li>
          <li>
            Standardisation and clear cap on what community partners get paid
            for being loan broker-partners Its like a global “Loan Franchise
            model” for accredited community groups
          </li>
        </ul>
      </div>
    </div> */}
    </div>
  )
}

export default SectionThree