import React, { useState } from 'react'
import FromOne from './from1'
import { useEffect } from 'react'
import Form2 from './form2'
import Signup from '../../Auth/form/signup'
import Login from '../../Auth/form/login'

const Loanform = () => {

    const [showData, setShowData] = useState(1);
    const datBtn = (key) => {
        setShowData(key)
    }


    useEffect(() => {
        window.scrollTo(0, 0)

    }, [])
    return (
        <div style={{ marginTop: "120px" }}>
            {/* <div className='container'>
                <div className='row  text-center bg-warning p-2' >
                    <NavLink className='col-lg-3 col-sm-6 col-6 nav-link text-addtocart-heading my-1'>Pre approval</NavLink>
                    <NavLink className='col-lg-3 col-sm-6 col-6 nav-link text-addtocart-heading my-1'>Registration</NavLink>
                    <NavLink className='col-lg-3 col-sm-6 col-6 nav-link text-addtocart-heading my-1 text-white'>Applicantion</NavLink>
                    <NavLink className='col-lg-3 col-sm-6 col-6 nav-link text-addtocart-heading my-1'>Review</NavLink>
                </div>
            </div> */}
            {
                showData===1? <FromOne datBtn={datBtn} />:null
            }
            {
                showData===2? <Signup datBtn={datBtn} />:null
            }
            {
                showData===3? <Form2 datBtn={datBtn} />:null
            }
            
            {
                showData===4? <Login datBtn={datBtn} />:null
            }
            
            
            
        </div>
    )
}

export default Loanform