/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { BsFacebook, BsLinkedin } from "react-icons/bs";
import { AiFillTwitterCircle } from "react-icons/ai";
import { RiInstagramFill } from "react-icons/ri";
import { NavLink, useParams } from "react-router-dom";
// import { BiDetail } from 'react-icons/bi';
import Testimonial from "../TestimonialCards";
import axiosInstance from "../../Axois/Axois";
import { addItem } from "../../Auth/slice/Addtochart";
import { dispatch } from "../../Auth/store";
import toast from "react-hot-toast";
// import { Flat, Heat, Nested } from '@alptugidin/react-circular-progress-bar'

const ProfileDetailes = () => {
  const [details, setDetails] = useState(false);
  const [data, setData] = useState([]);
  const { id } = useParams();
  const [amount, setamount] = useState("");
  useEffect(() => {
    axiosInstance
      .get(`/show/borrower/${id}`)
      .then((res) => {
        setData(res?.data?.borrower);
      })
      .catch((err) => {});
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const addfund = (item) => {
    if(!amount){
      toast.error("please fill amount")
      return
    }
    const itemWithAmount = { ...item, amount };
    dispatch(addItem(itemWithAmount))
    setamount('')
  };
  return (
    <div className="container" style={{ marginTop: "100px" }}>
      <div className="row pt-5">
        <div className="col-lg-6 col-md-12 col-12">
          <h2 className="fw-bold">{data?.name}</h2>
          <div className="d-flex gap-2 my-2">
            <span>
              <BsFacebook color="#3b5998" size={"35px"} />
            </span>
            <span>
              <BsLinkedin color="#0072b1" size={"35px"} />
            </span>
            <span>
              <AiFillTwitterCircle color="#0072b1" size={"40px"} />
            </span>
            <span>
              <RiInstagramFill color="#E1306C" size={"40px"} />
            </span>
          </div>

          <div className="d-flex gap-5 align-items-center my-2">
            <div style={{ fontSize: "15px" }}>
              <ul className="mx-0 px-0" style={{ listStyle: "none" }}>
                <li>
                  <strong className="fw-bold">Loan: </strong>${data?.loan}
                </li>
                <li>
                  <strong className="fw-bold">Repayment schedule: </strong>
                  {data?.repayment_schedule}
                </li>
              </ul>
            </div>
            <div style={{ fontSize: "15px" }}>
              <ul className="mx-0 px-0" style={{ listStyle: "none" }}>
                <li>
                  <strong className="fw-bold">Per month: </strong>
                  {data?.per_month}$
                </li>
                <li>
                  <strong className="fw-bold">Loan Length: </strong>
                  {data?.loan_length} Months
                </li>
              </ul>
            </div>
          </div>
          <div className="my-2">
            {" "}
            <label className="fw-bold">Loan For:</label> {data?.title}
          </div>
          <div className="my-2">
            {" "}
            <div className="fw-bold">About Me:</div>
            {data?.about}
          </div>
          <div className="">
            <input className="py-2 me-3 px-2" value={amount} onChange={(e)=>setamount(e.target.value)} placeholder="$" type="number"/>
            <button onClick={()=>addfund(data)} to="" className="btn btn-warning my-3 text-white">
              Donate
            </button>
          </div>
        </div>
        <div className="col-lg-6 col-md-12 col-12 d-flex justify-content-center imgprofile">
          <img className="mx-auto rounded" src={data.image} alt="imageaw" height={'400px'} />
        </div>
      </div>
      <div className="text-center my-4">
        <NavLink className="text-warning" onClick={() => setDetails(!details)}>
          learn More About business
        </NavLink>
      </div>
      {details ? (
        <div>
          <div className="row my-3">
            <div className="fw-bold">My Business</div>
            <div dangerouslySetInnerHTML={{ __html: data?.business }} />
          </div>
        </div>
      ) : null}
      <div className="text-center fs-1 fw-bold my-3">See success stories</div>
      <Testimonial />
    </div>
  );
};

export default ProfileDetailes;
