import React, { useEffect } from 'react'
import { NavLink } from 'react-router-dom';

const FromOne = ({datBtn}) => {

  useEffect(() => {
    window.scrollTo(0, 0)

}, [])
  return (
    <div className='container'>
      <div className='row '>
        <h3>What kind of business are you interested?</h3>
        <p>Find out if you qualify for a loan at Seed Out by answering a few quick questions.
          To qualify for a Seed Out loan, you must apply with a business idea that has to be located within Pakistan and any prohibited agenda behind your idea will not be accepted.</p>
      </div>
      <div className='row my-5 justify-content-between align-items-center' >
        <div className='col-lg-6 col-md-12 col-12 py-3 rounded' style={{ backgroundColor: "white", boxShadow: "7px 10px 12px rgba(1, 1, 1, 0.1)" }}>
          <div className='p-3' >

          <div className='row justify-content-between my-2'>
              <div className=' fw-bold'>Loan</div>
              <select class=" form-select form-select-lg mb-3 w-100" aria-label=".form-select-lg example">
                <option selected>Select Amount</option>
                <option value="1">2000 to 5000</option>
                <option value="2">5000 to 10,000</option>
                <option value="3">10,000 to 15,000</option>
              </select>
            </div>
        
            <div className='row justify-content-between my-2'>
              <div className=' fw-bold'>What are you interested in?</div>
              <select class=" form-select form-select-lg mb-3 w-100" aria-label=".form-select-lg example">
                <option selected>Select Category</option>
                <option value="1">Agriculture</option>
                <option value="2">Arts</option>
                <option value="3">Clothing</option>
                <option value="4">Construction</option>
                <option value="5">Educations</option>
                <option value="6">Entainment</option>
                <option value="7">Food</option>
                <option value="8">Health</option>
                <option value="9">Housing</option>
                <option value="10">Retails</option>
                <option value="11">Manufacturing</option>
                <option value="12">Services</option>
                <option value="13">Wholesale</option>
                <option value="14">Others</option>
              </select>
            </div>
           
            <div className='row justify-content-between my-2'>
              <div className='col-lg-4 col-md-4 col-12 fw-bold'>City</div>
              <select class="col-lg-8 col-md-8 col-12 form-select form-select-lg mb-3 w-100" aria-label=".form-select-lg example">
                <option selected>Select City</option>
                <option value="1">Faisalabad</option>
                <option value="2">Gujranwala</option>
                <option value="3">Lahore</option>
                <option value="3">Bahawalnagar</option>
              </select>
            </div>
         
          </div>
          <div className='p-3'>
            <div class="form-check my-2">
              <input class="form-check-input" type="checkbox" value="" id="flexCheckIndeterminate" />
              <label class="form-check-label" for="flexCheckIndeterminate">
                Business located within pakistan.
              </label>
            </div>
            <div class="form-check my-2">
              <input class="form-check-input" type="checkbox" value="" id="flexCheckIndeterminate" />
              <label class="form-check-label" for="flexCheckIndeterminate">
                Above 18.
              </label>
            </div>
            <div class="form-check my-2">
              <input class="form-check-input" type="checkbox" value="" id="flexCheckIndeterminate" />
              <label class="form-check-label" for="flexCheckIndeterminate">
                Do not want entire amount in cash.
              </label>
            </div>
            <div class="form-check my-2">
              <input class="form-check-input" type="checkbox" value="" id="flexCheckIndeterminate" />
              <label class="form-check-label" for="flexCheckIndeterminate">
                Deserving candidate for Zakat.
              </label>
            </div>
            <div class="form-check my-2">
              <input class="form-check-input" type="checkbox" value="" id="flexCheckIndeterminate" />
              <label class="form-check-label" for="flexCheckIndeterminate">
                Applying for a business loan.
              </label>
            </div>
          </div>
          <div className='text-end'>
          <NavLink onClick={()=>datBtn(2)} className='btn btn-warning text-white ms-2'>Continue</NavLink>
          </div>
        </div>
        <div className='d-lg-block col-lg-6 mx-auto d-md-none d-none'>
          <img src='./images/lonpage.png' alt='HappyLady' width={"100%"}/>
        </div>
      </div>
    </div>
  )
}

export default FromOne;