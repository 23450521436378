import { createSlice } from "@reduxjs/toolkit";
import { dispatch } from "../store";
import axiosInstance from "../../Axois/Axois";

const initialState = {
  isLoading: false,
  Islogined: false,
  User: [],
};

const slice = createSlice({
  name: "Login",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    logout(state) {
      state.isLoading = false;
      state.Islogined = false;
      state.User = [];
    },
    // GET depart
    getAttrSuccess(state, action) {
      state.isLoading = false;
      state.Islogined = true;
      state.User = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;
export const { startLoading, logout } = slice.actions;
// ----------------------------------------------------------------------

export function Logins(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axiosInstance.post("/login", data);
      console.log(response,'userresponse')
      dispatch(slice.actions.getAttrSuccess(response?.data));
      return response;
    } catch (error) {
      throw error.errors;
    }
  };
}

// ----------------------------------------------------------------------
export function Register(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axiosInstance.post("/register", data);
     return response
    } catch (error) {
      console.log(error, "errore");
      throw error.errors;
    }
  };
}
