import React from 'react'

const Guest = () => {
    return (
        <>
            <div className='row my-5 gap-5 p-2'>
                <div className='col-lg-7 col-md-12 col-12 p-4 border ' style={{ height: '100%' }}>
                    <div className='d-flex justify-content-between align-items-center'>
                        <div>Contribution Amount:</div>
                        <div className='border p-2'>PKR 2875</div>
                    </div>
                    <div className='fs-5 fw-bold mt-3'>Select Payment Method</div>
                    <div className='row my-3'>
                        <div className=' col-12 border'>
                          
                        </div>
                    </div>
                    <div className='row my-3'>
                        <div className='col-md-6 col-12'>
                            <div>Contact#:</div>
                            <input className='p-2 my-1 w-100' placeholder='000-1111-0000' />
                        </div>
                        <div className='col-md-6 col-12'>
                            <div>City Name:</div>
                            <input className='p-2 my-1 w-100' placeholder='Enter your city name' />
                        </div>
                    </div>
                    <div>
                        <div>Country Name:</div>
                        <select className="form-select w-100 my-2 " style={{ borderRadius: "0px" }} aria-label="Default select example">
                            <option selected>Pakistan</option>
                            <option value="1">India</option>
                            <option value="2">Nigeria</option>
                            <option value="3">Mexico</option>
                        </select>
                    </div>
                   
                </div>
                <div className='col-lg-4 col-md-12 col-12 p-0'>

                    <div className='border mb-3' style={{ height: 'auto' }}>
                        <div className='d-flex align-items-center bg-warning p-2'>
                            <div className='fs-6 fw-bold'>Donate to: Operational cost</div>
                        </div>
                        <div className='p-3'>
                        {/* <image src='./images/allied-bank-logo.png' alt="Allied Bank"  /> */}
                            <div className=''>Bank: Allied Bank</div>
                            <div className=''>Account Title: Share-Circle</div>
                            <div className=''>Account Number: 0010028779120016</div>
                            <div className=''>IBAN: PK81ABPA0010028779120016</div>
                            <div className=''>Swift Code: ABPAPKKA0175</div>
                            <div className=''>Branch Code: 0175</div>
                            <div className=''>Branch: Ladies Branch, 21 - Z - Block, DHA, Lahore Cantt.</div>
                        </div>
                    </div>
                    <div className='border mb-3' style={{ height: 'auto' }}>
                        <div className='d-flex align-items-center bg-warning p-2'>
                            <div className='fs-6 fw-bold'>Donate to: Revolving fund</div>
                        </div>
                        <div className='p-3'>
                            <div className=''>Bank: Mezaan Bank</div>
                            <div className=''>Account Title: Share-Circle</div>
                            <div className=''>Account Number: 0010028779120016</div>
                            <div className=''>IBAN: PK81ABPA0010028779120016</div>
                            <div className=''>Swift Code: ABPAPKKA0175</div>
                            <div className=''>Branch Code: 0175</div>
                            <div className=''>Branch: Ladies Branch, 21 - Z - Block, DHA, Lahore Cantt.</div>
                        </div>
                    </div>


                </div>
                <div>
                        <button className='btn btn-warning text-white'>Next-page</button>
                    </div>
            </div>
        </>
    )
}

export default Guest