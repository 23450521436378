import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
// import { Pagination, Navigation } from "swiper/modules";
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
const HomeBanner = () => {
  return (
    <div style={{marginTop:'100px'}}>
      <Swiper
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper"
      >
        <SwiperSlide>
          <div style={{ position: "absolute", top: "40%", left: "10%" }}>
            <h1 className="text-white slider-heading112"> Responsible, efficient lending <br /> to the unbanked</h1>
            {/* <p>Slide One</p> */}
          </div>
          <img src="../images/banner.jpg" alt="banner" width={"100%"} height={'100vh'}/>
        </SwiperSlide>
        <SwiperSlide>
          <div style={{ position: "absolute", top: "40%", left: "10%" }}>
            <h3 className="slider-heading112 text-light">One donation is recycled<br /> many times</h3>
            {/* <p>Slide two</p> */}
          </div>
          <img src="../images/CPSimage1.jpg" alt="banner" width={"100%"} />
        </SwiperSlide>
        <SwiperSlide>
        <div style={{ position: "absolute", top: "33%", left: "10%" }}>
            <h3 className="slider-heading112 text-light">As a responsible business<br />  borrower, we work with you to help <br />you grow your business</h3>
            {/* <p>Slide three</p> */}
          </div>
          <img  src="../images/HappyLadies.jpg" alt="banner" width={"100%"} />
        </SwiperSlide>
        
      </Swiper>
    </div>
  );
};

export default HomeBanner;
