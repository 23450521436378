import HomeBanner from "../component/homecomponent/HomeBanner";
import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
// import ProgressBar from "@ramonak/react-progress-bar";
import axiosInstance from "../Axois/Axois";
import  { addItem } from "../Auth/slice/Addtochart";
import { useDispatch } from "react-redux";
import toast from "react-hot-toast";
// import { RiShareForwardFill } from 'react-icons/ri';
const Donate = () => {
  const [entrepreneurDetails, setentrepreneurDetails] = useState([]);
  const [amount, setamount] = useState("");
  const dispatch = useDispatch()
  useEffect(() => {
    axiosInstance
      .get("/all/borrower")
      .then((res) => {
        setentrepreneurDetails(res?.data?.data);
        console.log(res, "borrower");
      })
      .catch((err) => {});
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  // const navigate = useNavigate();

  const addfund = (item) => {
    if(!amount){
      toast.error("please fill amount")
      return
    }
    const itemWithAmount = { ...item, amount };
    dispatch(addItem(itemWithAmount))
    setamount('')
  };

  return (
    <>
      <HomeBanner />
      <div className="container my-5">
        <h1 className="text-center my-2 fw-bold">Donate</h1>

        <p className="col-12">
          A donation from you will change many lives. It goes way beyond helping
          the first person or project that you donate to. As their 0% loans are
          repaid, your money is re-used, going to other needy entrepreneurs and
          projects on a similar 0% interest and repayment basis.
        </p>

        <div className="row justify-content-center align-items-center my-3">
          <h1 className="text-center pt-4 fw-bold my-3">How it works</h1>
          <div className="col-lg-4 col-md-12 col-12">
            <div className="cart-text">
              Aspiring or small business entrepreneurs in different parts of the
              world apply to Share-Circle with a brief explanation of their
              business idea. Once submitted, a Community Service Partner (CSP) -
              someone in their local community, who works with Share-Circle,
              will contact them and set up a meeting. The CSP’s job is to
              establish if the business idea or proposal is a viable and what
              the entrepreneur needs to be successful. When the business
              proposal is ready for action, the entrepreneur’s profile is
              finalised by the CSP and goes live on the Share-Circle platform
              for donors to see and support.
            </div>
          </div>
          <div className="col-lg-4 col-md-12 col-12">
            <div className="cart-text">
              Together with the entrepreneur, the CSP will develop a repayment
              plan. This plan is developed to be the least strenuous to the
              entrepreneur and their growing business, which is why all of
              Share-Circles loans are at 0% interest rate. This way, the
              entrepreneur only repays what they borrowed. For their continuous
              work and support, CSPs receive a financial contribution 10% of the
              entrepreneur’s loan value. This 10% is paid out in parts at each
              milestone the entrepreneur’s business plan meets. This way, the
              CSP are incentivised to stay engaged and support the entrepreneur
              on their business journey.
            </div>
          </div>
          <div className="col-lg-4 col-md-12 col-12">
            <div className="cart-text">
              As the entrepreneurs 0% loans are repaid, the money is reinvested
              in other entrepreneurs’ business plans and the circle begins
              again. Although the whole initial donation is being circulated in
              the Share-Circle entrepreneur community, additional funding to
              cover CSP costs are crucial. The CSP’s play a vital role in
              ensuring the entrepreneur’s business plan stays on track and grows
              to its full potential. The CSP’s involvement is what makes
              Share-Circle different from other microfinance charities, and they
              are the linchpin for helping the entrepreneurs succeed and
              continue the virtuous circle.
            </div>
          </div>
        </div>

        <div>
          <div className="text-center fs-1 fw-bold mt-3">Donations</div>
          <p>
            You can easily donate as a guest. Or if you register as a donor, you
            can log in to your profile to see how your donation is being
            recycled. When you{" "}
            <NavLink className="text-warning" to={"/donate"}>
              donate
            </NavLink>{" "}
            and help a specific entrepreneur’s business proposal, the additional
            10% to cover CSP costs is effectively{" "}
            <NavLink className="text-warning" to={"/"}>
              investing
            </NavLink>{" "}
            in the CSP support community.
          </p>
          <p>
            By creating a donor profile you gain access to see how your
            financial contributions are distributed and maintain better
            communication with the Share-Circle CSPs and entrepreneurs. You will
            also get email updates with success stories to see how your donation
            is continuing to spread opportunities for aspiring entrepreneurs.
            The donation function has credit card, debit card PayPal and Bitcoin
            / Lightning options.
          </p>
        </div>
        <div className="row   m-0 p-0">
          <h3 className="fw-bold text-center my-3">Entrepreneur Profile</h3>

          {entrepreneurDetails?.map((item) => {
            return (
              <div className="col-md-6 mt-3 d-flex justify-content-center col-lg-4 col-12 mb-lg-0 mb-md-2 mb-3">
                <div className="cart py-3">
                  <NavLink
                    to={`/profileDetail/${item?.id}`}
                    className="nav-link cart-img"
                  >
                    <img
                      className="rounded"
                      // onClick={handlenaviagete}
                      src={item.image}
                      alt="cartImage"
                      width={"90%"}
                      height={"250px"}
                    />
                  </NavLink>
                  <div className="px-3">
                    <h6 className="cart-heading mt-2">{item.name}</h6>
                    <h2 className="cart-description ">{item.location}</h2>
                    <p
                      className="cart-subheading my-2 overflow-hidden"
                      style={{ height: "50px" }}
                    >
                      {item.title}
                    </p>
                  </div>
                  {/* <div className="circle-progress-bar px-3">
                      <ProgressBar completed={item.ProgressBarcount} bgColor="orange" />
                    </div> */}
                  <div className="d-flex justify-content-between align-items-center my-1 px-3">
                    <p style={{ fontSize: "12px", fontWeight: "600" }}>
                      <span className="text-primary"> ${item.loan} Goal</span>
                    </p>
                    <p style={{ fontSize: "12px", fontWeight: "600" }}>
                      <span className="text-primary">
                        {item.remain_loan <= 1 ? item?.loan : item.remain_loan}$
                      </span>
                      <br /> Still Needed
                    </p>
                  </div>
                  <div className="d-flex justify-content-between align-items-center my-1 px-3">
                    <div style={{ fontSize: "12px" }}>
                      <strong className="fw-bold">Loan: </strong>
                      {item.loan} $
                    </div>
                    <div style={{ fontSize: "12px" }}>
                      <strong className="fw-bold">Loan Length: </strong>
                      {item.loan_length}
                    </div>
                  </div>
                  <div className="d-flex justify-content-between align-items-center my-1 px-3">
                    <div style={{ fontSize: "12px", marginBottom: "5px" }}>
                      <strong className="fw-bold">Repayment schedule: </strong>
                      {item.repayment_schedule}
                    </div>
                    <div style={{ fontSize: "12px", marginBottom: "5px" }}>
                      <strong className="fw-bold">Per month: </strong>
                      {item.per_month}
                    </div>
                  </div>
                  <div class="input-group px-3">
                    <input
                      type="number"
                      class="form-control py-2 "
                      placeholder="$"
                      onChange={(e) => setamount(e.target.value)}
                    />
                    <span
                      class="btn btn-warning"
                      style={{ color: "white", backgroundColor: "orange" }}
                      id="basic-addon2"
                      onClick={()=>addfund(item)}
                    >
                      fund
                    </span>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default Donate;
